import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dark-web-onetime-ad',
  templateUrl: './dark-web-onetime-ad.component.html',
  styleUrls: ['./dark-web-onetime-ad.component.scss']
})
export class DarkWebOnetimeAdComponent {
  
  constructor(public activeModal: NgbActiveModal) { }

}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {AlertService} from './alert.service';
import {Alert} from './alert';
import {NgClass, NgForOf, NgIf} from '@angular/common';
import {NgbAlert} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  standalone: true,
  imports: [
    NgForOf,
    NgbAlert,
    NgIf,
    NgClass,
  ],
})
export class AlertComponent implements OnInit, OnDestroy {

  alerts: Alert[];

  constructor(private alertService: AlertService) { }

  ngOnInit() {
      this.alerts = this.alertService.get();
  }

  refresh(alert) {
      this.alerts = this.alertService.get();
  }

  ngOnDestroy() {
    this.alerts = [];
  }

}

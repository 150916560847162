import {PersonalInformationCommon} from "../../common/personal-information-common";
import {NameMatchStat} from "../models/name-match";
import {NameDto} from "./name-dto";
import {Equifax} from "../equifax";
import {AddressDto} from "./address-dto";


export class PersonalInformationDto implements PersonalInformationCommon {

  CurrentAddress?: string;
  DateOfBirth?: string;
  Name?: string;
  PreviousAddresses?: Array<string>;

  constructor(nameMatchStat: NameMatchStat, currentAddress: Equifax.MatchedResidentialStructuredAddress, previousAddress) {
    this.Name = new NameDto(nameMatchStat.nameMatch.name).Name;
    this.DateOfBirth = nameMatchStat.nameMatch.dob;
    this.CurrentAddress = new AddressDto(currentAddress).Address;
    this.PreviousAddresses = previousAddress;
  }
}

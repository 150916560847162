import {Action, createSelector, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {SessionActions} from 'src/app/shared/state/session/session.actions';
import {
  SelectAgency,
  UpdateCreditAlerts,
  UpdateDisputes,
  UpdateReport,
  UpdateReportHistory,
} from 'src/app/shared/state/credit-report/credit-report.actions';
import {CreditAlertModel} from 'src/app/modules/alerts/models/credit-alert.model';
import {CreditReportInterface} from 'src/app/shared/models/report/credit-report.interface';
import {Agency} from 'src/app/shared/models/agency';
import {TransunionDispute} from 'src/app/modules/disputes/models/transunion/transunion-dispute';
import {ReportHistoryInterface} from 'src/app/shared/models/report-history.interface';
import {TransunionCreditAlertModel} from 'src/app/modules/alerts/models/transunion-credit-alert.model';
import {CreditAlerts} from 'src/app/modules/alerts/models/credit-alerts';
import ClearState = SessionActions.ClearState;

/**
 * Can be either Transunion or Equifax data
 */
export interface AgencyData {
  report: CreditReportInterface | null;
  alerts: CreditAlerts;
  disputes: any[];
  history: ReportHistoryInterface[];
}

export interface CreditReportStateModel {
  selectedAgency: Agency;
  transunion: AgencyData;
  equifax: AgencyData;

  // Just in case test data comes back we need a place to put it.
  none: AgencyData;
}


const stateDefaults: CreditReportStateModel = {
  selectedAgency: Agency.NONE,
  transunion: {alerts: [], disputes: [], report: undefined, history: []},
  equifax: {alerts: [], disputes: [], report: undefined, history: []},
  none: {alerts: [], disputes: [], report: undefined, history: []},
};


@State<CreditReportStateModel>({
  name: 'creditreport',
  defaults: stateDefaults,
})


@Injectable()
export class CreditReportState {

  /**
   * Selector that respects the state's selectedAgency value.  If the selected agency changes, this will emit the newly selected report.
   */
  @Selector([CreditReportState])
  static getReport(state: CreditReportStateModel): CreditReportInterface | null {
    switch (state.selectedAgency) {
      case Agency.TRANSUNION:
        return state.transunion.report;
      case Agency.EQUIFAX:
        return state.equifax.report;
      default:
        return state.none.report;
    }
  }

  /**
   * Selector for a specific agency.  This ignores the state's selectedAgency value.
   */
  static getReportForAgency(agency: Agency) {
    return createSelector([CreditReportState], (state: CreditReportStateModel): CreditReportInterface => {
      switch (agency) {
        case Agency.TRANSUNION:
          return state.transunion.report;
        case Agency.EQUIFAX:
          return state.equifax.report;
        default:
          return state.none.report;
      }
    });
  }

  /**
   * Selector for a specific agencies alerts.  This ignores the state's selectedAgency value.
   */
  static getAlerts(agency: Agency) {
    return createSelector([CreditReportState], (state: CreditReportStateModel): Array<CreditAlertModel|TransunionCreditAlertModel> => {
      switch (agency) {
        case Agency.TRANSUNION:
          return state.transunion?.alerts ?? [];
        case Agency.EQUIFAX:
          return state.equifax?.alerts ?? [];
        default:
          return state.none?.alerts ?? [];
      }
    });
  }

  /**
   * Selector for a specific agencies alerts.  This ignores the state's selectedAgency value.
   */
  static getDisputes(agency: Agency) {
    return createSelector([CreditReportState], (state: CreditReportStateModel): TransunionDispute[] => {
      switch (agency) {
        case Agency.TRANSUNION:
          return state.transunion?.disputes ?? [];
        case Agency.EQUIFAX:
          return state.equifax?.disputes ?? [];
        default:
          return state.none?.disputes ?? [];
      }
    });
  }

  /**
   * Selector for a specific agencies report history.  This ignores the state's selectedAgency value.
   */
  static getHistory(agency: Agency) {
    return createSelector([CreditReportState], (state: CreditReportStateModel): ReportHistoryInterface[] => {
      switch (agency) {
        case Agency.TRANSUNION:
          return state.transunion?.history ?? [];
        case Agency.EQUIFAX:
          return state.equifax?.history ?? [];
        default:
          return state.none?.history ?? [];
      }
    });
  }

  @Action(UpdateReport)
  updateReport(ctx: StateContext<CreditReportStateModel>, action: UpdateReport) {
    const state: CreditReportStateModel = {...ctx.getState()};

    switch (action.report.agency) {
      case Agency.NONE:
        state.none = {...state.none};
        state.none.report = action.report;
        break;
      case Agency.TRANSUNION:
        state.transunion = {...state.transunion};
        state.transunion.report = action.report;
        break;
      case Agency.EQUIFAX:
        state.equifax = {...state.equifax};
        state.equifax.report = action.report;
        break;
    }

    ctx.setState(state);
  }

  @Action(UpdateCreditAlerts)
  updateAlerts(ctx: StateContext<CreditReportStateModel>, action: UpdateCreditAlerts) {
    const state: CreditReportStateModel = {...ctx.getState()};

    switch (action.agency) {
      case Agency.NONE:
        state.none = {...state.none};
        state.none.alerts = action.alerts;
        break;
      case Agency.TRANSUNION:
        state.transunion = {...state.transunion};
        state.transunion.alerts = action.alerts;
        break;
      case Agency.EQUIFAX:
        state.equifax = {...state.equifax};
        state.equifax.alerts = action.alerts;
        break;
    }

    ctx.setState(state);
  }

  @Action(UpdateDisputes)
  updateDisputes(ctx: StateContext<CreditReportStateModel>, action: UpdateDisputes) {
    const state: CreditReportStateModel = {...ctx.getState()};

    switch (action.agency) {
      case Agency.NONE:
        state.none = {...state.none};
        state.none.disputes = action.disputes;
        break;
      case Agency.TRANSUNION:
        state.transunion = {...state.transunion};
        state.transunion.disputes = action.disputes;
        break;
      case Agency.EQUIFAX:
        state.equifax = {...state.equifax};
        state.equifax.disputes = action.disputes;
        break;
    }

    ctx.setState(state);
  }

  @Action(UpdateReportHistory)
  updateHistory(ctx: StateContext<CreditReportStateModel>, action: UpdateReportHistory) {
    const state: CreditReportStateModel = {...ctx.getState()};

    switch (action.agency) {
      case Agency.NONE:
        state.none = {...state.none};
        state.none.history = action.history;
        break;
      case Agency.TRANSUNION:
        state.transunion = {...state.transunion};
        state.transunion.history = action.history;
        break;
      case Agency.EQUIFAX:
        state.equifax = {...state.equifax};
        state.equifax.history = action.history;
        break;
    }

    ctx.setState(state);
  }

  @Action(SelectAgency)
  selectAgency(ctx: StateContext<CreditReportStateModel>, action: SelectAgency) {
    ctx.patchState({selectedAgency: action.agency});
  }

  @Action(ClearState)
  clearState(ctx: StateContext<CreditReportStateModel>) {
    ctx.setState(stateDefaults);
  }
}

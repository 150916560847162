import {Injectable} from '@angular/core';
import {IaoChainProvider} from './models/iao-chain-provider';
import {DineClubService} from './dine-club/dine-club.service';
import {GoCardlessService} from './go-cardless/go-cardless.service';
import {BrokenEnrollService} from './broken-enroll/broken-enroll.service';
import {DarkWebIaoModalService} from './dark-web/dark-web-iao-modal.service';
import {SmartOfferChainService} from './smart-offers/smart-offer-chain.service';
import {Store} from '@ngxs/store';
import {SessionState} from 'src/app/shared/state/session/session.state';
import {IaoPasswordCreateService} from './password-create/iao-password-create.service';
import {FastPerksService} from 'src/app/modules/iao-chain/fast-perks/fast-perks.service';
import {CreditConsultationIaoModalService} from 'src/app/modules/iao-chain/credit-consultation/credit-consultation-iao-modal.service';
import {CarGiantPopService} from './partners/car-giant-pop.service';
import {DarkWebOnetimeAdService} from './dark-web-onetime-ad/dark-web-onetime-ad.service';
import {AuthService} from 'src/app/core/services/auth.service';
import {filter} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class IaoChainProviders {

  private counter = 0;

  /**
   * Array of Modal Chain Providers, the services responsible for invoking a particular offer, intro or announcement
   *
   * !!! NOTE: Ordered by appearance priority
   *
   * !!!! NOTE: DASHBOARD INTROS MUST BE FIRST because they could fire on other pages the user might navigate to which would
   * be completely confusing.
   */
  public items: Array<IaoChainProvider> = [

    {
      // @ts-ignore
      service: IaoPasswordCreateService,
      options: {},
      testFlag: 'isTestPasswordCreate',
      delay: 100,
      iaoName: 'Create Password',
    },
    // {
    //   // @ts-ignore
    //   service: NewPortalAnnouncementService,
    //   options: {},
    //   testFlag: 'isTestNPA',
    //   iaoName: 'New portal announcement',
    // },
    {
      // @ts-ignore
      service: BrokenEnrollService,
      options: {},
      testFlag: 'isShowKba',  // Used in links from emails.
      delay: 1500,
      iaoName: 'Broken enroll',
    },
    {
      // @ts-ignore
      service: SmartOfferChainService,
      options: {},
      testFlag: 'isTestSmartOffers',
      iaoName: 'Smart offers',
    },
    {
      // @ts-ignore
      service: GoCardlessService,
      options: {},
      testFlag: 'isTestGoCardless',
      iaoName: 'Go Cardless',
    },
    {
      // @ts-ignore
      service: DarkWebIaoModalService,
      testFlag: 'isTestDarkWeb',
      iaoName: 'Dark web',
    },
    {
      // @ts-ignore
      service: DineClubService,
      options: {},
      testFlag: 'isTestDineClub',
      iaoName: 'Dine Club',
    },
    {
      // @ts-ignore
      service: FastPerksService,
      options: {},
      testFlag: 'isTestFastPerks',
      iaoName: 'Fast Perks',
    },
    {
      // @ts-ignore
      service: CreditConsultationIaoModalService,
      testFlag: 'isScheduleConsultation', // Used in links from emails.
      iaoName: 'Credit consultation',
    },

    /***** Off Dashboard Actions ****/
    /*{
      // @ts-ignore
      service: PartnerWebPostService,
      options: {},
      testFlag: 'isTestPartnerIntegration',
      iaoName: 'Partner web post',
    },*/

    {
      // @ts-ignore
      service: CarGiantPopService,
      options: {},
      testFlag: 'isTestCarGiant',
      iaoName: 'Cargiant Partnership',
    },

    {
      // @ts-ignore
      service: DarkWebOnetimeAdService,
      options: {},
      testFlag: 'isTestDarkWebOnetimeAd',
      iaoName: 'Dark Web One-time Ad',
    },

    // TODO: Disabled Intro because it doesn't match new dashboard
    // {
    //   // @ts-ignore
    //   service: HowToService,
    //   options: {},
    //   testFlag: 'isTestIntro',
    //   iaoName: 'Intro',
    // },
  ];

  constructor(authService: AuthService) {
    this.counter = 0;

    // Reset counter when we logout
    authService.loginStateChanges()
      .pipe(
        filter(isLoggedIn => !isLoggedIn)
      )
      .subscribe(() => this.reset());
  }

  public hasNext(): boolean {
    return (this.counter < this.items.length);
  }

  public next(): IaoChainProvider {
    return this.items[this.counter++];
  }

  public reset(): void {
    this.counter = 0;
  }

  public hasStarted(): boolean {
    return (this.counter > 0);
  }
}

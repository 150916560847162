<div class="welcome">

  <ng-template #pleaseWait>
    <h1 class="m-5 text-center">Please wait while we <br>contact the credit agency...</h1>
  </ng-template>

  <ng-template #addressStep>
    <div class="content">
      <div class="title">
        First, let's make sure we have the right address
      </div>

      <div class="address-input">
        <input
          id="pcainput"
          name="searchTerm"
          type="text"
          class="form-control"
          placeholder="Start typing a postcode, street or address"
          autocorrect="off"
          autocomplete="none">
      </div>

      <div class="address-output">
        <textarea
          name="formattedAddress"
          id="formattedAddress"
          wrap="soft"
          class="form-control"
          readonly
          [ngModel]="chosenAddressFormatted"
          rows="5">
        </textarea>
      </div>
<!--      <div class="invalid-feedback" *ngIf="">Invalid Address</div>-->

      <div class="butt">
        <button class="btn-primary btn-lg" (click)="register()" [disabled]="submitting">Submit</button>
      </div>
    </div>
  </ng-template>

  <ng-template #kbaStep>
    <div class="content">
      <div class="title">
        Please Answer These Questions
      </div>

      <div class="question-group" *ngFor="let group of kbaQuestions.groups">
        <h2 class="question-group-text">{{group.Text}}</h2>

        <div class="question" *ngFor="let question of group.Questions">
          <p>{{question.Text}} </p>

          <div class="answers">
            <div
              class="form-check answer"
              *ngFor="let answer of question.Answers.KbaAnswerOption"
              [ngClass]="{'correct-answer': !!answer.isCorrect}">

              <input
                type="radio"
                class="form-check-input"
                name="{{question.Id}}"
                id="{{question.Id}}_{{answer.Id}}"
                value="{{answer.Id}}"
                [(ngModel)]="answers[question.Id]">

              <label class="form-check-label" for="{{question.Id}}_{{answer.Id}}">{{answer.Text}}</label>
            </div>
          </div>
        </div>
      </div>

      <div class="butts my-4">
        <div>
          <div class="mb-2">
            <button class="btn btn-primary btn-lg rounded-pill" (click)="answer()" [disabled]="submitting">
              Submit Answers
            </button>
          </div>
          <div class="fw-light small">Answer questions to access credit report now.</div>
        </div>

        <div>
          <div class="mb-2">
            <button class="btn btn-secondary btn-lg rounded-pill" (click)="dismiss()" [disabled]="submitting">
              Skip <span class="d-none d-sm-inline">Answers</span>
            </button>
          </div>
          <div class="fw-light small">Answer questions to access credit report now.</div>
        </div>
      </div>

      <div class="disclaimer small fw-light mb-3 m-auto">
        By successfully completing and submitting the validation questions, you consent to {{account?.product?.name}} accessing and storing
        your credit file information from the Credit Reference agencies named within our terms and presenting this information to you within
        our online protal, including suggestions and actino points that may be of interest to you based on your credit profile and credit
        data.
      </div>

    </div>
  </ng-template>

  <div [ngTemplateOutlet]="currentTemplate"></div>

</div>

import {Equifax} from "./equifax";
import {IEquifaxRawReport} from "./i-equifax-raw-report";
import {NoticeCommon} from 'src/app/shared/models/report/common/notice-common';
import {SearchInquirySummarized} from 'src/app/shared/models/report/common/search-inquiry-summarized';
import {EquifaxTransformationService} from "./services/equifax-transformation-service";
import {CifasCaseCommon} from "../common/cifas-case-common";
import {AgencyRawReport} from 'src/app/shared/models/report/agency-raw-report';
import {PersonalInformationDto} from "./dto/personal-information-dto";
import {DistinctNames, NameMatch} from "./models/name-match";
import CourtAndInsolvencyInformation = Equifax.CourtAndInsolvencyInformation;
import ElectoralRollContainer = Equifax.ElectoralRollContainer;


export class EquifaxRawReport extends AgencyRawReport implements IEquifaxRawReport {

  clientRef: string;
  nonAddressSpecificData: Equifax.NonAddressSpecificData|null;  //Consumer.StructType.NonAddressSpecificData| null;
  jointSearch: Equifax.ResponseJointSearch | null;
  soleSearch: Equifax.ResponseSoleSearch | null;


  /*
NOTE: THE ELEMENTS BELOW ARE NOT PART OF AN EQUIFAX RAW REPORT.  This is to get an app up and running before we start splitting up
components into agency specific ones.  We can also implement these functions, drawing from actual EQ structure.
 */

  public AddressLinks: any;
  public Cifas: any;
  // public Details: any;
  //public ElectoralRoll: Array<ElectoralRollEquifax>;
  //public FinancialConnections: any;
  // public Moda: any;
  public NoticesOfCorrection: NoticeCommon[];
  // public OtherNames: any;
  public PersonalInformation: any;
  // public PublicInformation: any;
  // public ReportDetails: any;
  //public Rating: Rating;
  //public SearchHistory: any;


  private distinctNames: DistinctNames;


  constructor(obj?: Partial<IEquifaxRawReport>) {
    super();
    Object.assign(this, obj);
  }

  /**
   * Process actual report data into a format that is easier to digest for the application.  This should be done before the object is stored
   * in state management.
   */
  transform(): void {
    const equifaxTransformationService = new EquifaxTransformationService();

    this.distinctNames = new DistinctNames();

    this.AddressLinks = equifaxTransformationService.getAddressLinks(this.soleSearch);
    // this.ElectoralRoll = equifaxTransformationService.simplifyElectoralRoll(this.soleSearch);
    // equifaxTransformationService.convertJudgementsAndInsolvencies(this);
    // this.NoticesOfCorrection = equifaxTransformationService.extractNoticesOfCorrection(this.soleSearch);
    // this.Cifas = equifaxTransformationService.extractCifasData(this.soleSearch);
    // equifaxTransformationService.processFinancialAccounts(this);
    this.PersonalInformation = this.extractPersonalInformation(this.soleSearch);
  }

  getOtherNames(): Array<any> {
    return this.nonAddressSpecificData.aliases?.alias ?? [];
  }

  getFinancialConnections(): Array<any> {
    // const data = this.f;
    //
    // if (data.hasOwnProperty('FinancialConnection')) {
    //   return data.FinancialConnection;
    // }

    return [];
  }

  getCifas(): Array<CifasCaseCommon> {
    return this.Cifas;
  }

  /**
   * Doesn't exist in EQ
   */
  getPersonalSearches(): Array<SearchInquirySummarized> {
    return [];
  }

  getNoticesOfCorrection(): NoticeCommon[] {
    return this.NoticesOfCorrection;
  }

  /**
   * Get most recent ER record by start/end dates
   */
  // getElectoralRoll(): ElectoralRollCommon {
  //
  //   if (this.ElectoralRoll.length === 0) {
  //     return new ElectoralRollDto();
  //   } else {
  //     // First element should be most recent
  //     return new ElectoralRollDto(this.ElectoralRoll[0]);
  //   }
  // }

  /**
   * Get ER record on primary address
   */
  // getElectoralRollOnMatchedAddress(): ElectoralRollCommon {
  //
  //   for (const er of this.ElectoralRoll) {
  //     if (er.electoralRoll.sourcedFrom === 'ELR') {
  //       return new ElectoralRollDto(er);
  //     }
  //   }
  //
  //   return new ElectoralRollDto();
  // }

  /**
   * Combines all courtAndInsolvencyInformation from all addresses
   */
  getInsolvenciesSection(): CourtAndInsolvencyInformation[] {
    return this
      .getDataSection('courtAndInsolvencyInformationData')
      .reduce((result, current) => {
        return result.concat(current.courtAndInsolvencyInformation);
      }, []);
  }

  /**
   * Combines insight data from all addresses in the report.  These will be all financial accounts.
   *
   * @param insightDataSection A property of InsightAccountContainer
   * @return array
   * @see InsightAccountContainer
   */
  getInsightAccountContainerSection(insightDataSection: string) {
    const insightData = this.getDataSection('insightData');

    return insightData.reduce((carry, section) => {
      const sectionData = section[insightDataSection] ?? [];

      return carry.concat(sectionData);
    }, []);
  }

  /**
   * Returns an array containing the values of a specific property in the AddressSpecificData for each block in the suppliedAddressData
   * and linkedAddressData sections of the report.
   *
   * @param section  A property of AddressSpecificData to retrieve values from
   * @return array   An array containing the values of the specified property
   * @see AddressSpecificData
   */
  getDataSection(section: string) {
    const result = [];

    for (const block of this.soleSearch.primary.suppliedAddressData ?? []) {
      const blockSection = block.addressSpecificData[section] ?? null;
      if (blockSection) {
        result.push(blockSection);
      }
    }

    for (const block of this.soleSearch.primary.linkedAddressData ?? []) {
      const blockSection = block.addressSpecificData[section] ?? null;
      if (blockSection) {
        result.push(blockSection);
      }
    }

    return result;
  }


  /**
   * Search for all distinct names marked 'A' (the sole Subject)  throughout report and count up occurrences
   * @param soleSearch
   */
  extractPersonalInformation(soleSearch: Equifax.ResponseSoleSearch) {
    // Get Name (options could be Electoral Roll, Notices of Correction, or specific accounts from insightData

    const equifaxTransformationService = new EquifaxTransformationService();
    equifaxTransformationService
      .getAllNamesFromAccountData(this.soleSearch)
      .forEach((name: NameMatch) => {
        this.distinctNames.addNameOrUpdateCount(name);
      });

    const electoralRollContainers: Array<ElectoralRollContainer> = this.getDataSection('electoralRollData') as unknown as Array<ElectoralRollContainer>;

    electoralRollContainers?.forEach((electoralRollContainer: Equifax.ElectoralRollContainer) => {
      electoralRollContainer?.electoralRoll?.forEach((electoralRoll: Equifax.ElectoralRoll) => {
        if (electoralRoll.nameMatchStatus === 'A') {
          //Electoral roll doesn't always have DOB
          this.distinctNames.addNameOrUpdateCount({
            name: electoralRoll.name,
            nameMatchStatus: electoralRoll.nameMatchStatus,
            dob: electoralRoll.dob || null
          });
        }
      })
    });

    //TODO: Add Notices of Correction

    const name = this.distinctNames.getMostUsed()
    const searchAddress = soleSearch?.primary?.suppliedAddressData[0];

    return new PersonalInformationDto(name, searchAddress.matchedAddress.address, this.AddressLinks.addresses);
  }
}

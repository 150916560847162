<div class="rating">
  <div class="row justify-content-between align-items-center">
    <div class="col-4 font-color-alt text-end align-items-center small">Credit Rating</div>

    <div class="col-4 star">
      <div class="d-flex justify-content-between align-items-center star-bar-container">
        <i class="fas fa-star font-ukc-blue" *ngFor="let item of [].constructor(rating?.RatingValue)"></i>
        <i class="far fa-star font-ukc-blue" *ngFor="let item of [].constructor(5-rating?.RatingValue)"></i>
      </div>
    </div>

    <div class="col-4 small text-start align-items-center font-color-alt out-of">{{ rating?.RatingValue }} out of 5</div>
  </div>
</div>

import {Component} from '@angular/core';
import {CreditReportService} from 'src/app/shared/services/credit-report.service';
import {ISummaryStats} from 'src/app/shared/models/report/common/i-summary-stats';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Agency} from 'src/app/shared/models/agency';


@Component({
  selector: 'app-summary-section-nav',
  templateUrl: './summary-section-nav.component.html',
  styleUrls: ['./summary-section-nav.component.scss'],
})
export class SummarySectionNavComponent {

  openDisputes$: Observable<Array<any>>;
  summaryStats$: Observable<ISummaryStats>;
  isTransunion$: Observable<boolean>;

  constructor(
    private reportService: CreditReportService,
  ) {
    this.isTransunion$ = reportService.getSelectedAgency()
      .pipe(
        map(agency => agency === Agency.TRANSUNION)
      );

    this.openDisputes$ = this.reportService
      .getDisputes()
      .pipe(
        map(resp => {
          const disputes = Object.keys(resp).map(key => resp[key]);

          return disputes.filter(val => val['meta'].is_open);
        }),
      );

    this.summaryStats$ = this.reportService
      .get()
      .pipe(
        map(rpt => rpt.summary),
      );
  }
}

import {CreditReportInterface} from 'src/app/shared/models/report/credit-report.interface';
import {EquifaxRawReport} from 'src/app/shared/models/report/equifax/equifax-raw-report';
import {CreditReport} from 'src/app/shared/models/report/credit-report';
import {RatingCommon} from "../common/rating-common";
import {PersonalInformationCommon} from "../common/personal-information-common";


/**
 * This is the full data file we have on the customer.address.  That includes the "Report" which is merely
 * the unmodified data from the agency.  It also includes a lot of summary and analysis alongside
 * it.
 */
export class EquifaxCreditReport extends CreditReport {

  report: EquifaxRawReport;

  constructor(obj?: Partial<CreditReportInterface>) {
    super(obj);

    if (!obj) {
      return;
    }

    Object.assign(this, obj);

    if (Object.prototype.hasOwnProperty.call(obj, 'report')) {
      this.report = new EquifaxRawReport(obj.report as EquifaxRawReport);
    }
  }

  // getAccountById(accountId: number) {
  // }

  getOtherName(AliasId: number) {
    // Not really any additional info for EQ to bother with opening a detail view
  }

  getRating(): RatingCommon {
    const score = this.summary.score;

    let rating: number;
    if (score >= 810) {
      rating = 5;
    } else if (score >= 671) {
      rating = 4;
    } else if (score >= 531) {
      rating = 3;
    } else if (score >= 439) {
      rating = 2;
    } else {
      rating = 1;
    }

    return {
      RatingValue: rating,
      Score: score,
      ScoreMax: 1000,
    };
  }

  getPersonalInformation(): PersonalInformationCommon {
    return this.report.PersonalInformation;
  }
}

import {Injectable} from '@angular/core';
import {take} from 'rxjs/operators';
import {Chainable} from '../models/chainable';
import {AccountService} from 'src/app/shared/services/account.service';
import {Account} from 'src/app/shared/models/account';
import {DarkWebComponent} from './dark-web.component';

import {genericErrorObserver} from 'src/app/shared/observables/generic-error.observer';
import {IaoModalResultEnum} from '../iao-modal-result.enum';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DarkWebIaoModalService implements Chainable {

  private account: Account;

  constructor(private accountService: AccountService,
              private modalService: NgbModal) {
  }

  canDisplay(): boolean {
    // Don't show for prepaid customers, as they don't have an initial dark web fetch.  UKC-2535
    return !this.account.isDataPartner() && !this.account.settings.hasSeenDarkwebModal;
  }

  onIaoClose(): void {
    this.accountService
      .updateSetting('hasSeenDarkwebModal', true)
      .subscribe(genericErrorObserver);
  }

  resolveDependencies(): Promise<any> {
    const obs = this.accountService.getAccount()
      .pipe(
        take(1)
      );
    obs.subscribe(account => this.account = account);
    return firstValueFrom(obs);
  }

  run(): Promise<IaoModalResultEnum> {
    const modalRef = this.modalService.open(DarkWebComponent, {size: 'md'});

    return modalRef.result;
  }
}

import {Injectable} from '@angular/core';
import {NgbModalOptions} from '@ng-bootstrap/ng-bootstrap/modal/modal-config';
import {BrokenEnrollKbaIntroComponent} from './components/welcome/broken-enroll-kba-intro.component';
import {RegisterComponent} from './components/register/register.component';
import {UserStatus} from 'src/app/shared/models/user-status';
import {CreditReportService} from 'src/app/shared/services/credit-report.service';
import {ThinFileComponent} from './components/thin-file/thin-file.component';
import {AccountService} from 'src/app/shared/services/account.service';
import {IaoModalResultEnum} from '../iao-modal-result.enum';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Chainable} from '../models/chainable';
import {ExitSurveyService} from 'src/app/modules/exit-survey/exit-survey.service';
import {ToastService} from 'src/app/core/components/toasts/toast.service';
import {IAccount} from 'src/app/shared/models/account';
import {MixpanelService} from 'src/app/shared/mixpanel/mixpanel.service';
import {genericErrorObserver} from 'src/app/shared/observables/generic-error.observer';


@Injectable({
  providedIn: 'root'
})
export class BrokenEnrollService implements Chainable {

  public result: Promise<IaoModalResultEnum>;
  private account: IAccount;

  private isModalOpen = false;

  /**
   * Resolve callback for the promise returned by run().  Execute when control should be passed back to the caller of this IAO.
   */
  private resolveRunPromise: (value: (IaoModalResultEnum | PromiseLike<IaoModalResultEnum>)) => void;

  private modalOptions: NgbModalOptions = {
    size: 'lg',
    // scrollable: true,  // Appears to disable scrolling if set
    // windowClass: 'broken-enroll-modals',
    // backdropClass: 'broken-enroll-backdrop',
    // keyboard: false, // dont want this closeable
    // backdrop: 'static' // prevents closing
  };

  constructor(
    private accountService: AccountService,
    private modalService: NgbModal,
    private reportService: CreditReportService,
    private router: Router,
    private exitSurvey: ExitSurveyService,
    private toastService: ToastService) {
  }

  resolveDependencies(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.accountService.getAccount()
        .subscribe((account: IAccount) => {
          this.account = account;
          resolve();
        });
    });
  }

  canDisplay() {
    return (this.account.user.status === UserStatus.BrokenEnrollment);
  }

  run(): Promise<IaoModalResultEnum> {
    this.result = new Promise<IaoModalResultEnum>((resolve, reject) => {
      this.resolveRunPromise = resolve;
      this.start();
    });
    return this.result;
  }

  /**
   * Just show the modal without caring about the end result.  If you need to know what status the customer ended up with, use run()
   */
  start(): void {

    if (this.isModalOpen) {
      return;
    }


    const modalOptions: NgbModalOptions = { ...this.modalOptions };
    modalOptions.size = '';

    this.modalService
      .open(BrokenEnrollKbaIntroComponent, modalOptions)
      .result
      .then(
        () => {
          this.isModalOpen = false;
          this.register();
        },
        () => {
          this.isModalOpen = false;
          MixpanelService.track('Broken enrollment: skipped');

          if (this.resolveRunPromise) {
            this.resolveRunPromise(IaoModalResultEnum.ContinueChain);
          }
        }
      );

    this.isModalOpen = true;
  }

  private register(): void {
    MixpanelService.track('ShowModal broken enrollment register');

    this.modalService
      .open(RegisterComponent, this.modalOptions)
      .result
      .then(
        value => {
          switch (value) {
            case UserStatus.Manual:
              this.router.navigateByUrl('/info/manual-verify');

              if (this.resolveRunPromise) {
                // Since we are navigating off the dashboard, we should stop the chain from showing any more.
                this.resolveRunPromise(IaoModalResultEnum.StopChain);
              }
              break;

            case UserStatus.ThinFile:
              this.thinFile();
              break;

            case UserStatus.Cancelled:
              this.router.navigateByUrl('/info/no-match');

              // Don't need to ask if they found what they were looking for if we cancel them.
              this.exitSurvey.clearTimeout();

              if (this.resolveRunPromise) {
                // Since we are navigating off the dashboard, we should stop the chain from showing any more.
                this.resolveRunPromise(IaoModalResultEnum.StopChain);
              }
              break;

            case 'velocity-check':
              this.toastService.error('Too many KBA attempts too quickly.  Try again later.');
              if (this.resolveRunPromise) {
                this.resolveRunPromise(IaoModalResultEnum.ContinueChain);
              }
              break;

            default:
              if (this.resolveRunPromise) {
                this.resolveRunPromise(IaoModalResultEnum.ContinueChain);
              }
              break;
          }
        },
        () => {
          if (this.resolveRunPromise) {
            this.resolveRunPromise(IaoModalResultEnum.ContinueChain);
          }
        }
      );
  }

  private thinFile(): void {
    this.modalOptions.size = 'xl';

    this.modalService
      .open(ThinFileComponent, this.modalOptions)
      .result
      .then(
        () => {
          this.modalOptions.size = 'lg';
          if (this.resolveRunPromise) {
            this.resolveRunPromise(IaoModalResultEnum.ContinueChain);
          }
        },
        () => {
          if (this.resolveRunPromise) {
            this.resolveRunPromise(IaoModalResultEnum.ContinueChain);
          }
        }
      );
  }

  onIaoClose(): void {
    // Maybe we have a report now?  Fetch a new copy
    this.accountService.fetch().subscribe(genericErrorObserver);
  }
}

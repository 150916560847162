<div class="modal-header">
  <h4 class="modal-title">Membership update!</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>
    We’re pleased to announce that we’ve added a new feature to your membership plan.
    As part of your current membership, we carry out an extensive search of the Dark Web
    using your email address to reveal any of your personal information that might have
    been leaked online which can place you at risk of being a victim of fraud or identity theft.
  </p>
  <p>
    We have enhanced this search to now include your personal mobile telephone number.
    This new feature will reveal any instances where your mobile telephone number has
    been leaked online which could lead to spam callers, text messages or phishing activity
    from criminals posing as your bank or a government agency in an attempt to commit
    fraud against you.
  </p>
  <p>
    Once you’ve accessed your enhanced Dark Web report, we can guide you through the next
    steps you should take to ensure your identity and personal details are safe.
  </p>
  <p>
    Contact our support team directly <a href="https://ukcreditratings.com/credit/contact" target="_blank">HERE</a>, or receive a personalised, one to one
    telephone review of your Dark Web and Credit report by booking a call with one of our
    expert support team <a href="https://calendly.com/ukcr/financial-monitoring-review" target="_blank">HERE</a>.
  </p>
  <p>
    You can also review your current membership level <a routerLink="account/member-profile">HERE</a>.
  </p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="activeModal.close('Close click')">Close</button>
</div>

import {Component} from '@angular/core';
import {ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {GoCardlessRequest} from 'src/app/shared/models/account/go-cardless-request';
import {ToastService} from 'src/app/core/components/toasts/toast.service';
import {AccountService} from 'src/app/shared/services/account.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AsyncPipe, NgClass, NgIf} from '@angular/common';
import {genericErrorObserver} from 'src/app/shared/observables/generic-error.observer';
import {GoCardlessService} from 'src/app/modules/iao-chain/go-cardless/go-cardless.service';
import {BillingScheme} from 'src/app/shared/models/billing-scheme.interface';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-go-cardless',
  templateUrl: './go-cardless.component.html',
  styleUrls: ['./go-cardless.component.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgClass,
    NgIf,
    AsyncPipe,
  ],
})
export class GoCardlessComponent {

  public goCardlessForm: UntypedFormGroup;
  public isSubmitted = false;
  billingScheme: BillingScheme;
  currentPrice$: Observable<string>;

  constructor(
    private accountService: AccountService,
    private formBuilder: UntypedFormBuilder,
    private notificationService: ToastService,
    private activeModal: NgbActiveModal,
    private gocardlessService: GoCardlessService,
  ) {
    this.initializeForm();

    this.currentPrice$ = this.accountService
      .getAccount()
      .pipe(map(acct => acct.user.recurring_price));

    this.gocardlessService
      .getDiscountedBillingScheme()
      .subscribe({
        next: scheme => this.billingScheme = scheme,
        error: e => console.error(e),
      });
  }

  private initializeForm() {
    this.goCardlessForm = this.formBuilder.group({
      sortCode: ['', [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.minLength(6),
        Validators.maxLength(6)
      ]],
      verifySortCode: ['', [
        Validators.required,
        this.checkSortCodesMatch('sortcodematch')
      ]],
      accountNumber: ['', [
        Validators.required,
        Validators.pattern('^[0-9]*$')
      ]],
      verifyAccountNumber: ['', [
        Validators.required,
        this.checkAccountNumbersMatch('accountnumbermatch')
      ]],
    });
  }


  save() {
    this.isSubmitted = true;

    if (this.goCardlessForm.dirty && this.goCardlessForm.valid) {

      this.accountService
        .recordClickLog('Direct Debit', 'Yes')
        .subscribe(genericErrorObserver);

      const goCardlessRequest: GoCardlessRequest = this.goCardlessForm.value;

      this.accountService.signupGoCardless(goCardlessRequest)
        .subscribe({
          next: () => {
            this.notificationService.success('It will take 3-5 business days to confirm the change.');
            this.close();
          },
          error: error => {
            // TODO: Capture 422 errors (depends on what checkout returns for validation)
            this.handleValidationErrors(error);
            this.close();
          },
        });
    }
  }

  validate(fieldName, errorName) {
    return (this.formControls
      && this.formControls[fieldName].touched
      && this.formControls[fieldName].hasError(errorName));
  }


  checkSortCodesMatch(errorType: string) {
    return (input: UntypedFormControl) => {
      if (!input.root || !input.root.get('verifySortCode')) {
        return null;
      }
      const isMatch = (input.root.get('sortCode').value === input.value);
      return isMatch ? null : { [errorType]: true };
    };
  }


  checkAccountNumbersMatch(errorType: string) {
    return (input: UntypedFormControl) => {
      if (!input.root || !input.root.get('verifyAccountNumber')) {
        return null;
      }
      const isMatch = (input.root.get('accountNumber').value === input.value);
      return isMatch ? null : { [errorType]: true };
    };
  }


  get formControls() {
    return (this.goCardlessForm) ? this.goCardlessForm.controls : null;
  }


  handleValidationErrors(err) {
    if (err.status === 422) {
      Object.keys(err.error.errors).map(key => {
        this.notificationService.error(err.error.errors[key]);
      });
    }
  }

  close() {
    this.accountService
      .recordClickLog('Direct Debit', 'No')
      .subscribe(genericErrorObserver);

    this.activeModal.close();
  }
}

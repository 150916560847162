import {CifasSubjectCommon} from "../../common/cifas-subject-common";
import {CifasCaseCommon} from "../../common/cifas-case-common";
import {CifasCase} from "../definitions/CifasCase";
import {CifasSubject} from "../definitions/CifasSubject";



/*
{
  "ApplicationDate": "2018-02-22T00:00:00",
  "CaseId": "-4",
  "CaseType": "Protective registration",
  "DataMatchingRules": {
    "string": [
      "Same individual"
    ],
    "internArray": null,
    "internArrayIsArray": null,
    "internArrayOffset": null
  },
  "ExpiryDate": "2019-02-22T00:00:00",
  "Facility": "Protective registration",
  "FilingReasons": {
    "string": [
      "Impersonation - current address fraud",
      "Protective registration sought"
    ],
    "internArray": null,
    "internArrayIsArray": null,
    "internArrayOffset": null
  },
  "ManagingMember": "CIFAS Office",
  "Notices": {},
  "OwningMember": "CIFAS Office",
  "ProductCode": "ZIPR",
  "Subjects": {
    "CifasSubject": [
      {
        "CurrentAddress": "2 Animal Street Test Town X9 9AL",
        "DateOfBirth": "2017-05-09T00:00:00",
        "Name": "Mrs Kerry Dog",
        "SubjectRole": "Protective registration",
        "SubjectRoleQualifier": "Protective registration (00)"
      }
    ]
  },
  "SupplyDate": "2018-02-22T00:00:00"
}
*/


export class TuCifasDto implements CifasCaseCommon {

  ApplicationDate: string;
  CaseId: string;
  CaseType: string;
  DataMatchingRules: any;
  ExpiryDate: string;
  Facility: string;
  FilingReasons: Array<string>;   //TODO: Actual is string?: Array<string>;
  ManagingMember: string;
  Notices: Array<any>;
  OwningMember: string;
  ProductCode: string;
  Subjects: Array<CifasSubjectCommon> = [];
  SupplyDate: string;

  constructor(cifasCase: CifasCase) {
    this.ApplicationDate = cifasCase.ApplicationDate;
    this.CaseId = cifasCase.CaseId;
    this.CaseType = cifasCase.CaseType;
    this.DataMatchingRules = cifasCase.DataMatchingRules.string;  //moves nested string array up
    this.ExpiryDate = cifasCase.ExpiryDate
    this.Facility = cifasCase.Facility
    this.FilingReasons = cifasCase.FilingReasons.string;  //moves nested string array up
    this.ManagingMember = cifasCase.ManagingMember
    this.Notices = cifasCase.Notices.Notice;
    this.OwningMember = cifasCase.OwningMember;
    this.ProductCode = cifasCase.ProductCode;

    cifasCase.Subjects.CifasSubject.forEach((cifasSubject: CifasSubject) => {
      this.Subjects.push(<CifasSubjectCommon>cifasSubject);
    });

    this.SupplyDate = cifasCase.SupplyDate;
  }

}


import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ProductInterface} from 'src/app/shared/models/product.interface';

@Component({
  selector: 'app-dine-club',
  templateUrl: './dine-club.component.html',
  styleUrls: ['./dine-club.component.scss'],
})
export class DineClubComponent {

  public product: ProductInterface;

  constructor(
    public activeModal: NgbActiveModal
  ) {
  }
}

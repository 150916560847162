<div class="cta-item h-100 d-flex flex-column justify-content-between" [ngSwitch]="cta.status" [attr.cta-id]="cta.id">

  <div>
    <div class="category-icon-cont" *ngIf="cta.category_icon">
      <img class="category-icon" src='{{cta.category_tab_icon}}' width="25" height="25"/>
    </div>

    <i *ngSwitchCase="ctaStatus.COMPLETED" class="status-icon fas fa-lg text-primary fa-check-circle fa-1x"></i>
    <i *ngSwitchCase="ctaStatus.DISMISSED" class="status-icon fas fa-lg text-muted fa-minus-circle fa-1x"></i>

    <div class="icon-container d-flex justify-content-center">
      <div class="corner-spacer flex-shrink-0"></div>
      <img class="flex-shrink-1 img-fluid align-self-end" src='/assets/images/cta/{{cta.icon}}' />
      <div class="corner-spacer flex-shrink-0"></div>
    </div>
  </div>

  <div>
    <h4 class="mt-3">{{cta.title}}</h4>
    <p class="desc my-3" [innerHTML]="cta.desc"></p>
  </div>

  <div>
    <div class="px-lg-2 px-md-1 d-grid">
      <button
        class="btn btn-lg rounded-pill cta-button"
        [ngClass]="cta.status === ctaStatus.COMPLETED ? 'btn-secondary' : 'btn-primary'"
        (click)="ctaButtonClick()">
        {{cta.btn_label}}
      </button>
    </div>

    <div class="small mt-3">
      <ng-container *ngIf="cta.status !== ctaStatus.DISMISSED && cta.status !== ctaStatus.COMPLETED">
        <a class="status-btn clickable complete" (click)="complete()">Complete</a> | <a class="status-btn clickable dismiss" (click)="dismiss()">Dismiss</a>
      </ng-container>

      <ng-container *ngSwitchCase="ctaStatus.DISMISSED">
        <a class="status-btn clickable complete" (click)="complete()">Complete</a> | <p class="status-btn d-inline-block">Dismissed</p>
      </ng-container>

      <p class="d-inline-block status-btn" *ngSwitchCase="ctaStatus.COMPLETED">Completed</p>
    </div>
  </div>

</div>

import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ISummaryStats} from 'src/app/shared/models/report/common/i-summary-stats';
import {CreditReportService} from 'src/app/shared/services/credit-report.service';
import {CreditConsultationIaoModalService} from 'src/app/modules/iao-chain/credit-consultation/credit-consultation-iao-modal.service';
import {CalendlyEvent} from 'src/app/shared/models/calendly/event';
import {Observable, Subscription} from 'rxjs';
import {EventState} from 'src/app/shared/state/calendly/events.state';
import {Store} from '@ngxs/store';
import {IAccount} from 'src/app/shared/models/account';
import {UserStatus} from 'src/app/shared/models/user-status';
import {Router} from '@angular/router';
import {take} from 'rxjs/operators';
import {AccountService} from 'src/app/shared/services/account.service';
import {CreditReport} from 'src/app/shared/models/report/credit-report';

@Component({
  selector: 'app-car-giant',
  templateUrl: './car-giant.component.html',
  styleUrls: ['./car-giant.component.scss']
})
export class CarGiantComponent implements OnInit, OnDestroy {

  @ViewChild('accepted', {static: true}) acceptedTemplate: TemplateRef<any>;
  @ViewChild('declined', {static: true}) declinedTemplate: TemplateRef<any>;
  @ViewChild('noscoremv', {static: true}) noscoreMvTemplate: TemplateRef<any>;
  @ViewChild('noscorebe', {static: true}) noscoreBeTemplate: TemplateRef<any>;
  @ViewChild('noscorethin', {static: true}) noscoreThinTemplate: TemplateRef<any>;
  @ViewChild('noscoreother', {static: true}) noscoreOtherTemplate: TemplateRef<any>;

  public templateName: TemplateRef<any>;

  public summaryStats: ISummaryStats;

  public event$: Observable<CalendlyEvent> = this.store.select(EventState.getEvent);
  public hasEvent$: Observable<boolean> = this.store.select(EventState.hasEvent);

  private selectReport: Subscription;
  public status: string;

  private scoreThreshold = 300;

  constructor(
    private router: Router,
    private reportService: CreditReportService,
    public activeModal: NgbActiveModal,
    private store: Store,
    private accountService: AccountService,
    private consultModalService: CreditConsultationIaoModalService
  ) {}

  ngOnInit(): void {
    this.accountService.getAccount()
      .pipe(take(1))
      .subscribe((resp: IAccount) => {
        this.status = resp.user.status;
        this.setTemplate();
      });
  }

  private setTemplate() {
    this.selectReport = this.reportService
      .get()
      .subscribe((creditReport: CreditReport) => {
        this.summaryStats = creditReport.getSummaryStats();

        // Set Template based on score
        if (this.summaryStats) {
          if (this.summaryStats.score >= this.scoreThreshold) {
            this.templateName = this.acceptedTemplate;
          } else {
            this.templateName = this.declinedTemplate;
          }
        } else {
          // Messaging for MV, BE, and Thin
          if (this.status === UserStatus.Manual) {
            this.templateName = this.noscoreMvTemplate;
          } else if (this.status === UserStatus.BrokenEnrollment) {
            this.templateName = this.noscoreBeTemplate;
          } else if (this.status === UserStatus.ThinFile) {
            this.templateName = this.noscoreThinTemplate;
          } else {
            this.templateName = this.noscoreOtherTemplate;
          }
        }
      });
  }

  navigate(to: string) {
    this.close();
    this.router.navigate([to]);
  }

  close() {
    this.activeModal.close();
  }

  public book(): void {
    this.consultModalService.run().then(
      () => console.log('Credit consult modal done'),
      () => console.log('Credit consult modal abandoned'),
    );
  }

  ngOnDestroy(): void {
    this.selectReport.unsubscribe();
  }
}

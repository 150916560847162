import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, RouterOutlet} from '@angular/router';
import {fadeAnimation, slideDownAnimation} from '../summary/summary-animations';
import {transition, trigger, useAnimation} from '@angular/animations';
import {SummaryBlankComponent} from '../summary/summary-blank/summary-blank.component';
import {IaoChainService} from '../../../iao-chain/iao-chain.service';
import {Subscription} from 'rxjs';
import {ScrollService} from 'src/app/shared/services/scroll.service';
import {IAccount} from 'src/app/shared/models/account';
import {AccountService} from 'src/app/shared/services/account.service';
import {SmartOfferService} from 'src/app/shared/services/smart-offer-service';
import {CtaActionService} from 'src/app/modules/dashboard/services/cta-action.service';
import {BtnAction} from 'src/app/shared/models/cta/btn-action';
import {BtnActionType} from 'src/app/shared/models/cta/btn-action-type.enum';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    // slider,
    // transformer,
    // fade,
    trigger('summaryAnimations', [
      transition('none => *', useAnimation(slideDownAnimation)),
      transition('fader1 => *', useAnimation(fadeAnimation)),
      transition('fader2 => *', useAnimation(fadeAnimation)),
      transition('fader3 => *', useAnimation(fadeAnimation)),
      transition('fader4 => *', useAnimation(fadeAnimation)),
      transition('fader5 => *', useAnimation(fadeAnimation)),
      transition('fader6 => *', useAnimation(fadeAnimation)),
    ])
    // stepper
  ]
})
export class HomeComponent implements OnInit, OnDestroy {

  public account: IAccount;
  public lastRouterOutletEvent = null;
  private selectAccount: Subscription;


  @ViewChild('outlet', {static: true}) outlet: RouterOutlet;
  @ViewChild('dashtop', {static: true}) dashtop: ElementRef;
  @ViewChild('summarySectionNav', {static: true}) summaryNav: ElementRef;
  /**
   * Which animation summaryAnimations is on.  This changes whenever the RouterOutlet activates a new route component.
   */
  public currentAnimation = 'none';
  /**
   * We do something different on the first animation change to prevent Angular issues.
   */
  private firstAnimation = true;

  constructor(
    private accountService: AccountService,
    private iaoChainService: IaoChainService,
    private scrollService: ScrollService,
    private route: ActivatedRoute,
    private smartOfferService: SmartOfferService,
    private ctaActionService: CtaActionService,
  ) { }

  ngOnInit() {
    this.selectAccount = this.accountService.getAccount()
      .subscribe((account: IAccount) => {
      this.account = account;

      this.proceed();
    });

    this.registerScrollable();
  }

  /**
   * Take action after landing on the dashboard and loading the account object.  This can jump to any number of actions, like opening smart
   * offers or viewing a wiki page.  We fall back to starting the IAO chain.
   */
  private proceed() {
    const smartOfferCategory = this.route.snapshot.queryParamMap.get('smartOfferCategory');
    const creditLadder = this.route.snapshot.queryParamMap.get('creditLadder');
    const caspian = this.route.snapshot.queryParamMap.get('caspian');

    if (smartOfferCategory) {
      let smartcat = parseInt(smartOfferCategory);
      // 0 means show all categories
      if (smartcat === 0) {
        smartcat = null;
      }
      this.smartOfferService.open(smartcat);

    } else if (creditLadder) {

      // Should match btn_action for CTA 39
      const action: BtnAction = {
        type: BtnActionType.MODAL,
        action: 'CreditLadderComponent',
        options: {size: 'md'},
      };
      this.ctaActionService.handleAction(action, this.account);

    } else if (caspian) {

      // Should match btn_action for CTA 31
      const action: BtnAction = {
        type: BtnActionType.MODAL,
        action: 'CaspianInsuranceComponent',
        options: {size: 'md'},
      };
      this.ctaActionService.handleAction(action, this.account);

    } else {

      // Run Intros, Announcements, and Offers
      this.iaoChainService.start();

    }
  }

  private registerScrollable() {
    this.scrollService.register('summarySectionNav', this.summaryNav);
  }

  /**
   * Listen for summary Router Outlet Events and scroll to summary section
   */
  onActivate(event) {
    // I have been unsuccessful in preventing an Expression Changed After Check error without delaying the first animation.  To test this,
    // disable the setTimeout bit, go to a route like dashboard/home/(summary:summary-payment-history), and refresh the page.
    // It started after upgrading to Angular 15.  See this: https://angular.io/guide/update-to-version-15#v15-bc-09
    // Perhaps we could move some if this to events directly from the Router instead of through the RouterOutlet activation?
    if (this.firstAnimation) {
      setTimeout(() => this.currentAnimation = this.outlet.activatedRouteData['animation'], 5);
      this.firstAnimation = false;
    } else {
      this.currentAnimation = this.outlet.activatedRouteData['animation'];
    }

    this.scroll(event);
  }

  /**
   * Fix Scroll After Cta Grid Animation Completes
   */
  onCtaAnimationFinished() {
    this.scroll(this.lastRouterOutletEvent);
  }

  /**
   * Scroll to proper location by evaluating RouterOutletEvents for active component
   *
   * TODO: This needs revisit since all the dashboard changes.  We used to scroll top on login, but if user was interacting with
   * the summary section and coming back to dashboard, we wanted to scroll to summary so they are back where they left off with
   * exploring their numbers.
   */
  private scroll(event) {
    // Store last router outlet event so we can pass it to the scroll handler after the animation event
    this.lastRouterOutletEvent = event;
    if (event.activated.instance instanceof SummaryBlankComponent) {
      this.scrollToTop();
    } else {
      this.scrollToSummarySection();
    }
  }

  /**
   * Scroll to Summary Section
   */
  scrollToSummarySection() {
    this.scrollService.scrollToViewChild('summarySectionNav');
  }

  /**
   * Scroll to top of dashboard
   */
  scrollToTop() {
    this.dashtop.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  ngOnDestroy(): void {
    this.selectAccount.unsubscribe();
  }
}

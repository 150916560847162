import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {IaoModalResultEnum} from '../iao-modal-result.enum';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-dark-web',
  templateUrl: './dark-web.component.html',
  styleUrls: ['./dark-web.component.scss']
})
export class DarkWebComponent {

  constructor(private router: Router,
              public activeModal: NgbActiveModal
  ) { }

  continue(): void {
    this.router.navigateByUrl('/dark-web');
    this.activeModal.close(IaoModalResultEnum.StopChain);
  }
}

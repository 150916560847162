<div class="go-cardless px-3 py-5">
  <div class="text-center">
    <h1 class="">Save 25%</h1>

    <h4>Pay <span class="cross">&pound;{{ currentPrice$ | async }}</span>
      <span class="new-price"> &pound;{{ billingScheme?.recurring_price }}</span> when you sign up for Direct Debit</h4>
<!--    <img class="mb-4" src="assets/images/Subline.svg" alt="Pay less when you sign up for Direct Debit"/>-->
  </div>

  <form (ngSubmit)="save()" [formGroup]="goCardlessForm">

    <div class="mb-3">
      <label for="sortCode" class="form-label">Sort Code</label>
      <input
        aria-label="Sort Code"
        type="text"
        class="form-control position-static"
        formControlName="sortCode"
        id="sortCode"
        [ngClass]="{ 'is-invalid': isSubmitted && formControls.sortCode.errors }">
      <div class="invalid-feedback" *ngIf="validate('sortCode', 'required')">Required</div>
      <div class="invalid-feedback" *ngIf="validate('sortCode', 'pattern')">Must be a number</div>
      <div class="invalid-feedback" *ngIf="validate('sortCode', 'minlength') || validate('sortCode', 'maxlength')">Must be 6 digits</div>
    </div>

    <div class="mb-3">
      <label for="verifySortCode" class="form-label">Verify Sort Code</label>
      <input
        aria-label="Sort Code"
        type="text"
        class="form-control position-static"
        formControlName="verifySortCode"
        id="verifySortCode"
        [ngClass]="{ 'is-invalid': isSubmitted && formControls.verifySortCode.errors }">
      <div class="invalid-feedback" *ngIf="validate('verifySortCode', 'required')">Required</div>
      <div class="invalid-feedback" *ngIf="validate('verifySortCode', 'sortcodematch')">Sort Codes do not match</div>
    </div>

    <div class="mb-3">
      <label for="accountNumber" class="form-label">Account Number</label>
      <input type="text"
             class="form-control position-static"
             formControlName="accountNumber"
             id="accountNumber"
             [ngClass]="{ 'is-invalid': isSubmitted && formControls.accountNumber.errors }">
      <div class="invalid-feedback" *ngIf="validate('accountNumber', 'pattern')">Must be a number</div>
      <div class="invalid-feedback"
           *ngIf="formControls && formControls.accountNumber.touched && formControls.accountNumber.hasError('required')">Required
      </div>
    </div>

    <div class="mb-3">
      <label for="verifyAccountNumber" class="form-label">Verify Account Number</label>
      <input type="text"
             class="form-control position-static"
             formControlName="verifyAccountNumber"
             id="verifyAccountNumber"
             [ngClass]="{ 'is-invalid': isSubmitted && formControls.verifyAccountNumber.errors }">
      <div class="invalid-feedback"
           *ngIf="formControls && formControls.verifyAccountNumber.touched && formControls.verifyAccountNumber.hasError('required')">
        Required
      </div>
      <div class="invalid-feedback"
           *ngIf="formControls && formControls.verifyAccountNumber.touched && formControls.verifyAccountNumber.hasError('accountnumbermatch')">
        Account numbers do not match
      </div>
    </div>

    <div class="butts my-4">
      <div>
        <button class="btn btn-primary btn-lg rounded-pill" type="submit">
          Submit & Save
        </button>
      </div>

      <div>
        <button class="btn btn-secondary btn-lg rounded-pill" (click)="close()">
          No Thanks
        </button>
      </div>
    </div>

    <!--      <div class="mb-3">-->
    <!--        <button type="submit" class="btn btn-block btn-lg btn-primary" id="go-cardless-iao-button">Submit & Save</button>-->
    <!--      </div>-->
    <!--      <a class="font-small font-ukc-blue d-block text-center h" (click)="close()">No thanks, I want to pay for it</a>-->
  </form>

  <p class="small fw-light text-center disclaimer">After your free trial, any continued subscription charge will be reduced to
    &pound;{{ billingScheme?.recurring_price }}</p>
</div>

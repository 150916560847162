import {CreditReportInterface} from 'src/app/shared/models/report/credit-report.interface';
import {TransunionRawReport} from 'src/app/shared/models/report/transunion/transunion-raw-report';
import {AccountType} from 'src/app/shared/models/report/account-type.enum';
import {IAccount} from 'src/app/shared/models/report/i-account';
import {CreditReport} from 'src/app/shared/models/report/credit-report';
import {ITransunionRawReport} from "./i-transunion-raw-report";
import {ElectoralRollCommon} from "../common/electoral-roll-common";
import {RatingCommon} from "../common/rating-common";
import {PersonalInformationCommon} from "../common/personal-information-common";


/**
 * This is the full data file we have on the customer.  That includes the "Report" which is merely
 * the unmodified data from the agency.  It also includes a lot of summary and analaysis alongside
 * it.
 */
export class TransunionCreditReport extends CreditReport {

  report: TransunionRawReport;

  constructor(obj?: Partial<CreditReportInterface>) {
    super(obj);

    if (!obj) {
      return;
    }

    Object.assign(this, obj);

    if (Object.prototype.hasOwnProperty.call(obj, 'report')) {
      this.report = new TransunionRawReport(obj.report as ITransunionRawReport);
    }
  }

  /**
   * Get Transunion version of a financial account
   */
  getAccountTransunion(accountType: AccountType, accountId: number): IAccount | null {
    return this.report.getFinancialAccountInformationTransunion(accountType)
      .find(acct => acct.AccountId === accountId);
  }

  // getAccountById(accountId: number) {
  //   for (let i = 0; i < 5; i++) {
  //     const acct = this.getAccount(i, accountId);
  //
  //     if (acct) {
  //       return acct;
  //     }
  //   }
  // }

  getOtherName(AliasId: number) {
    let data = null;

    this.report
      .getOtherNames()
      .forEach(value => {
        if (value['AliasId'] == AliasId) {
          data = value;
        }
      });

    return data;
  }

  getFinancialConnection(AssociateId: number) {
    let data = null;

    this.report
      .getFinancialConnections()
      .forEach(value => {
        if (value['AssociateId'] == AssociateId) {
          data = value;
        }
      });

    return data;
  }

  getCifasCase(CaseId: string) {
    let data = null;

    this.report
      .getCifas()
      .forEach(value => {
        if (value['CaseId'] == CaseId) {
          //TODO: DTO this
          data = value;
        }
      });

    return data;
  }

  getRating(): RatingCommon {
    return {
      RatingValue: +this.report?.Rating?.RatingValue,
      Score: +this.report?.Rating?.Score,
      ScoreMax: 710,
    };
  }

  getElectoralRoll(): ElectoralRollCommon {
    return this.report.ElectoralRoll;
  }

  getPersonalInformation(): PersonalInformationCommon {
    return this.report.PersonalInformation as unknown as PersonalInformationCommon;
  }
}

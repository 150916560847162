<div #dashtop style="padding-top:55px;">
  <div class="home-bg">
    <div class="home-bg-2">

      <div class="container">

<!--        <app-report-failure-alert *ngIf="creditReport.reportUnavailable && account.user.status === userStatus.Active"></app-report-failure-alert>-->

        <div class="boxes-wrapper">
          <app-darkweb-summary class="box"></app-darkweb-summary>

          <div class="small-boxes">
            <app-credit-score class="box" [userStatus]="account?.user.status"></app-credit-score>
            <app-credit-alerts-box class="box"></app-credit-alerts-box>
            <app-credit-consultation-box class="box"></app-credit-consultation-box>
            <app-fast-perks-status class="box"></app-fast-perks-status>
          </div>
        </div>

<!--        <div class="feature-buttons">-->
<!--          <app-dark-web-status></app-dark-web-status>-->
<!--        </div>-->

<!--        <app-cta-slider limit=3></app-cta-slider>-->

        <app-offer-category-ctas></app-offer-category-ctas>

      </div>
    </div>
  </div>
</div>

<div #summarySectionNav class="pb-2">
  <app-summary-section-nav></app-summary-section-nav>
</div>

<div class="summary-section-container" [ngClass]="{'displayed': currentAnimation !== 'none'}">
  <div [@summaryAnimations]="currentAnimation" style="position: relative;" id="summary-details">
    <router-outlet #outlet="outlet" name="summary" (activate)="onActivate(outlet)"></router-outlet>
  </div>
</div>

<div class="birds">
  <div class="container">
    <h4 class="pt-3">Dashboard Notifications</h4>
    <app-cta-grid limit=0 (ctaAnimationDoneEvent)="onCtaAnimationFinished()"></app-cta-grid>
  </div>
</div>

<div class="pad-resp-card">

  <div class="d-grid col-10 col-sm-10 col-md-8 mx-auto">
    <div class="bg-dark rounded-bottom text-white mx-2">
      <h6 class="p-3 m-0 text-center">EXCLUSIVE MEMBERS BENEFIT</h6>
    </div>
  </div>

  <h3 class="text-center mt-4 mb-3">ACCESS TO YOUR <span class="d-none d-sm-inline">{{product?.name}}</span>  <span class="font-ukc-blue">PERKS</span> &
      <span class="font-ukc-blue">DISCOUNTS</span> HAS BEEN <span class="font-ukc-blue">ACTIVATED</span>
  </h3>


  <div class="d-grid col-10 col-sm-8 mx-auto pad-resp">
      <img src="assets/images/iao/fast-perks/fast-perks-app.png" class="img-fluid" />
  </div>

  <div class="d-grid col mx-auto pad-resp mt-4 mb-2 text-center">
    <p><b>We've brought you all the best discounts at high street brands, national attractions and so much more.  Why pay more when you can pay Less?</b></p>

    <p>Access your <a [href]="fastPerksUrl"  target="_blank">Perks & Discounts</a> via our home page, through the Menu bar, or via the Fast Perks App using your {{product?.name}} Email to log in.</p>
  </div>

  <div class="d-grid col-6 mx-auto pt-3 pb-5">
    <button class="btn btn-primary px-4 rounded-pill btn-lg"
            appRecordClickEvent="Continue"
            appRecordClickPage="Fast Perks"
            (click)="activeModal.close()" id="dark-web-iao-button">Continue</button>
  </div>

</div>



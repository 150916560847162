import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, distinctUntilChanged, map, share, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {LoginRequest} from '../models/login-request';
import {ToastService} from '../components/toasts/toast.service';
import {Observable, of} from 'rxjs';
import {LoginResponse} from '../models/login-response';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Store} from '@ngxs/store';
import {SessionState} from 'src/app/shared/state/session/session.state';
import {SessionActions} from 'src/app/shared/state/session/session.actions';
import ClearState = SessionActions.ClearState;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  /**
   * ID for Auto Logout Timer
   */
  private autoLogoutTimerId: number;
  private authCheck$: Observable<boolean> | null = null;

  constructor(private http: HttpClient,
              private store: Store,
              private router: Router,
              private notificationService: ToastService,
              private modalService: NgbModal
  ) {
  }

  updateAuthState(state: boolean): void {
    this.store.dispatch(new SessionActions.UpdateAuthenticated(state));
  }

  /**
   * Ping the server to see if the user is logged in
   */
  checkAuthenticationState(): Observable<boolean> {
    if (!this.authCheck$) {
      this.authCheck$ = this.http
        .get('/api/auth/check', { observe: 'response' })
        .pipe(
          // Turn success into a boolean based on HTTP status code
          map(resp => resp.status === 200),

          // Catch 4xx (or 5xx errors???) and turn it into false
          catchError(() => of(false)),

          // Ensure our state is accurate
          tap(isLoggedIn => this.updateAuthState(isLoggedIn)),

          share(),
        );

      // Allow the call again in 60 seconds.
      setTimeout(() => this.authCheck$ = null, 60000);
    }

    return this.authCheck$;
  }

  /**
   * Observable to subscribe to for login state changes.  Will emit false if they're logged out, and true when they log back in.
   */
  loginStateChanges(): Observable<boolean> {
    return this.store
      .select(SessionState.isAuthenticated)
      .pipe(
        distinctUntilChanged(),
      );
  }

  /**
   * Logout - Clear auth tokens, save last route and kick user back to login page
   *
   * @param showExpirationError Pops a toast message explaining their session has expired
   * @param navigateToLogout    Navigates to the logout page instead of the login page
   */
  logout(showExpirationError = false, navigateToLogout = false): void {
    // Close any open modals and logout
    this.modalService.dismissAll();

    this.http
      .post('/api/auth/logout', {})
      .subscribe({error: err => console.error(err)});

    // Clear Application State
    this.store.dispatch(new ClearState());

    if (this.router.url !== '/auth/login') {
      let url = '/auth/login';

      // Most the time it's a better experience to go to the login page.
      if (navigateToLogout) {
        url = '/auth/logout';
      }

      this.router.navigateByUrl(url);
    }

    if (showExpirationError) {
      this.notificationService.error('Your session has expired.  Please log in.');
    }

    clearTimeout(this.autoLogoutTimerId);
  }


  // /**
  //  * To be called after the user returns from google's oauth page.  Google will
  //  * pass us a code, that we must validate server side.
  //  * @param code
  //  * @returns {Observable<any>}
  //  */
  // authenticateGoogleCode(code) {
  //     const postData = {code: code};
  //
  //     return this.http.post('/api/auth/google', postData).pipe(map(authenticateSuccess.bind(this)));
  //
  //     function authenticateSuccess(resp) {
  //         const rememberMe = false;
  //         this.tokenService.storeAuthenticationToken(resp['token'], rememberMe);
  //         this.setExpirationTimer(5);
  //     }
  // }


  localLogin(loginRequest: LoginRequest): Observable<LoginResponse> {
    return this.http
      .post<LoginResponse>('/api/auth/login', loginRequest)
      .pipe(
        tap(
          resp => {
            this.updateAuthState(true);
            this.setExpirationTimer(5);
          }
        )
      );
  }

  googleLogin(code: string, redirectUri: string): Observable<LoginResponse> {
    return this.http
      .post<LoginResponse>('/api/auth/google', {code, redirectUri})
      .pipe(
        tap(
          resp => {
            this.updateAuthState(true);
            this.setExpirationTimer(5);
          }
        )
      );
  }

  otpLogin(loginRequest: LoginRequest): Observable<LoginResponse> {
    return this.http
      .post<LoginResponse>('/api/auth/one-time-password', loginRequest)
      .pipe(
        tap(
          resp => {
            this.updateAuthState(true);
            this.setExpirationTimer(5);
          }
        )
      );
  }

  /**
   * Set our Sanctum csrf cookie value to be used later
   */
  setCsrfCookie() {
    return this.http.get('/api/sanctum/csrf-cookie');
  }

  /**
   * Starts time to auto logout user based on JWT expiration time
   */
  private setExpirationTimer(offsetSeconds: number = 0): void {

    let timeout = 3600000;     // 3,600,000 = 1 hour in milliseconds

    // If we have a formal token expiration time from server, use that instead.
    /*const expirationDate = this.jwtHelper.getTokenExpirationDate(this.getToken());
    if (expirationDate !== null) {
      timeout = (expirationDate.valueOf() - (offsetSeconds * 1000)) - new Date().valueOf();
    }*/

    // set the logout timer
    this.autoLogoutTimerId = setTimeout(() => {
      this.logout();
    }, timeout);
  }

}

import {Component} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {AccountService} from 'src/app/shared/services/account.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NgClass, NgIf} from '@angular/common';
import {PasswordComponent} from '../../account/components/password/password.component';
import {MixpanelService} from 'src/app/shared/mixpanel/mixpanel.service';

@Component({
  selector: 'app-password-create',
  templateUrl: './password-create.component.html',
  styleUrls: ['./password-create.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    ReactiveFormsModule,
    PasswordComponent,
    NgIf,
  ]
})
export class PasswordCreateComponent {

  constructor(
    private accountService: AccountService,
    private activeModal: NgbActiveModal,
  ) {
  }

  passwordSet() {
    MixpanelService.track('Password created');

    this.accountService
      .updateSetting('forceCreatePassword', false)
      .subscribe(() => {
      });

    this.accountService
      .recordClickLog('Password', 'Continue')
      .subscribe(() => {
      });

    this.activeModal.close();
  }
}

import {Equifax} from "../equifax";

export class AddressDto {
  Address: string;

  constructor(address: Equifax.MatchedResidentialStructuredAddress) {
    const fields = [address.number, address.street1, address.postTown, address.postcode, address.county];

    // Combine all fields by space.
    this.Address = fields.filter(x => Boolean(x)).join(' ');

    // Remove leading zeroes from address, since number comes as 00035
    this.Address = this.Address.replace(/^0+/, '');
  }
}


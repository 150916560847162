import {Directive, HostListener, Input} from '@angular/core';
import {AccountService} from '../services/account.service';
import {genericErrorObserver} from '../observables/generic-error.observer';

@Directive({
  selector: '[appRecordClickEvent]',
  standalone: true
})
export class RecordClickDirective {

  @Input('appRecordClickEvent') action: any;
  @Input('appRecordClickPage') page: any;

  constructor(private accountService: AccountService) { }

  @HostListener('click')
  onClick() {
    this.accountService
      .recordClickLog(this.page,  this.action)
      .subscribe(genericErrorObserver);
  }
}

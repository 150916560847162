import {CreditReport} from 'src/app/shared/models/report/credit-report';
import {CreditReportInterface} from 'src/app/shared/models/report/credit-report.interface';
import {RatingCommon} from "./common/rating-common";
import {PersonalInformationCommon} from "./common/personal-information-common";

/**
 * Credit report for empty / invalid reports, or odd cases of test data.
 */
export class CreditReportNone extends CreditReport {

  constructor(obj?: Partial<CreditReportInterface>) {
    super(obj);
  }

  getOtherName(AliasId: number) {
  }

  getRating(): RatingCommon {
    return undefined;
  }

  getPersonalInformation(): PersonalInformationCommon {
    return undefined;
  }
}

import {Equifax} from "../equifax";


export class NameDto {
  readonly Name: string;

  constructor(name: Equifax.Name) {
    const names = [name.title, name.forename, name.middleName, name.surname];
    this.Name = names.filter(x => Boolean(x)).join(' ');
  }
}




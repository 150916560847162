import {Injectable} from '@angular/core';
import {combineLatest, filter, Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {catchError, map, share, tap, timeout} from 'rxjs/operators';
import {ToastService} from '../../core/components/toasts/toast.service';
import {Store} from '@ngxs/store';
import {
  SelectAgency,
  UpdateCreditAlerts,
  UpdateDisputes,
  UpdateReport,
  UpdateReportHistory,
} from 'src/app/shared/state/credit-report/credit-report.actions';
import {CreditReportState} from 'src/app/shared/state/credit-report/credit-report-state.service';
import {CreditAlertModel} from 'src/app/modules/alerts/models/credit-alert.model';
import {AgencyCreditReport, CreditReportInterface} from 'src/app/shared/models/report/credit-report.interface';
import {CreditReport} from 'src/app/shared/models/report/credit-report';
import {CreditReportNone} from 'src/app/shared/models/report/credit-report-none';
import {Agency} from 'src/app/shared/models/agency';
import {TransunionCreditReport} from 'src/app/shared/models/report/transunion/transunion-credit-report';
import {EquifaxCreditReport} from 'src/app/shared/models/report/equifax/equifax-credit-report';
import {AccountService} from 'src/app/shared/services/account.service';
import {genericErrorObserver} from 'src/app/shared/observables/generic-error.observer';
import {ReportHistoryInterface} from 'src/app/shared/models/report-history.interface';
import {TransunionDispute} from 'src/app/modules/disputes/models/transunion/transunion-dispute';
import {CreditAlerts} from 'src/app/modules/alerts/models/credit-alerts';
import {UserStatus} from 'src/app/shared/models/user-status';

@Injectable({
  providedIn: 'root',
})
export class CreditReportService {

  /**
   * Create a new subclass instance deterined by the agency field.
   * This used to be in the CreditReport object, but that creates cyclical dependencies resulting in "cannot access before initialization"
   */
  static createReport(obj?: Partial<CreditReportInterface>): AgencyCreditReport {
    switch (obj?.agency) {
      case Agency.TRANSUNION:
        return new TransunionCreditReport(obj);
      case Agency.EQUIFAX:
        return new EquifaxCreditReport(obj);
      default:
        return new CreditReportNone(obj);
    }
  }

  constructor(
    private http: HttpClient,
    private store: Store,
    private toastService: ToastService,
    private accountService: AccountService,
  ) {
    combineLatest([
      this.accountService.getAccount(),
      this.getSelectedAgency(),
    ]).subscribe(([acct, agency]) => {
      // The app starts with agency none.  Don't bother pulling them, otherwise you will get two requests: first for NONE then for the real
      // agency.
      if (agency === Agency.NONE && acct.user.status != UserStatus.BrokenEnrollment) {
        return;
      }

      // Only pull if they have an account.  Not MV, thin file, broken enroll
      // if (acct.user.status === 'active') {
        this.fetch().subscribe(genericErrorObserver);
        this.fetchAlerts().subscribe(genericErrorObserver);
        this.fetchHistory().subscribe(genericErrorObserver);
        this.fetchDisputes().subscribe(genericErrorObserver);
      // }
    });
  }

  /*
   * CREDIT REPORT
   */

  /**
   * Pull the credit report from the state.  If no agency is supplied, we look to the state's selected agency.
   */
  get(agency: Agency|null = null): Observable<CreditReport> {
    // Default to getting an observable that respects the state's selectedAgency value.  If the selected agency changes, an event will emit
    // on the observable with the newly selected report.
    let fn = CreditReportState.getReport;

    // If a specific agency was passed, return an observable for that agency only.  The observable remains unaffected by the value of
    // selected agency.  This should only be used on agency specific pages, when you want to guarantee you're getting a TU or EQ report.
    if (agency != null) {
      fn = CreditReportState.getReportForAgency(agency);
    }

    return this.store
      .select(fn)
      .pipe(
        filter(rpt => rpt != null),
        map(rpt => {
          return CreditReportService.createReport(rpt);
        }),
      );
  }

  /**
   * Pull the credit report from the state, allowing for null values from the state..  If no agency is supplied, we look to the state's
   * selected agency.
   */
  getNullable(agency: Agency|null = null): Observable<CreditReport|null> {
    // Default to getting an observable that respects the state's selectedAgency value.  If the selected agency changes, an event will emit
    // on the observable with the newly selected report.
    let fn = CreditReportState.getReport;

    // If a specific agency was passed, return an observable for that agency only.  The observable remains unaffected by the value of
    // selected agency.  This should only be used on agency specific pages, when you want to guarantee you're getting a TU or EQ report.
    if (agency != null) {
      fn = CreditReportState.getReportForAgency(agency);
    }

    return this.store
      .select(fn)
      .pipe(
        map(rpt => {
          if (rpt) {
            return CreditReportService.createReport(rpt);
          }

          return null;
        }),
      );
  }

  fetch(agency: Agency|null = null): Observable<CreditReport> {
    if (agency == null) {
      agency = this.getSelectedAgencySnapshot();
    }

    // Something seems to be fetching the report before the account is loaded and the agency is selected.  This should only be temporary.
    // Sentry should log the component history that is making this call.
    // This will screw up broken enrollment customers.  They don't have an agency but need this call to go through for CTAs to be fetched.
    // changing the requested agency to something other than the selected one screws up the polling subscription in CtaService
    // if (agency === Agency.NONE) {
    //   console.error('Agency NONE fetch attempted.');
    //   agency = Agency.TRANSUNION;
    // }

    return this.http
      .get<CreditReportInterface>('/api/report', {params: {agency}})
      .pipe(
        timeout(20000),
        share(),
        catchError(() => {
          // catches timeout and returns an observable picked up by map()
          const cr = new CreditReportNone();
          cr.reportUnavailable = true;
          return of(cr);
        }),
        map(
          resp => {
            // resp could be null if they are manual verify or thin file.
            const cr = CreditReportService.createReport(resp);

            if (cr.reportUnavailable === true) {
              this.toastService.error('We are unable to fetch your credit report at this time. Please try again later.', 'Credit Report Unavailable');
            } else if (cr.mvOrThinfile === false) {
              // Run all transformations before we store it in the state.
              cr.report.transform();
            }

            return cr;
          },
        ),
        tap(
          report => {
            // This will store an actual credit report class instance in the state.  However, don't count on it existing in the future.  We
            // should still build new objects using CreditReportService.createReport() when removing from state.
            this.store.dispatch(new UpdateReport(report));
          },
        ),
      );
  }

  /*
   * ALERTS
   */

  /**
   * Pull alerts from the state.  If no agency is supplied, the one selected in the state will be used.
   */
  getAlerts(agency: Agency|null = null): Observable<CreditAlerts> {
    if (agency == null) {
      agency = this.getSelectedAgencySnapshot();
    }

    return this.store.select(CreditReportState.getAlerts(agency));
  }

  /**
   * Fetch alerts from the server and store them in the state.  If no agency specified, the one selected in the state will be used.
   */
  fetchAlerts(agency: Agency|null = null): Observable<CreditAlertModel[]> {
    if (agency == null) {
      agency = this.getSelectedAgencySnapshot();
    }

    if (agency === Agency.NONE) {
      return of([]);
    }

    return this.http
      .get<CreditAlertModel[]>('/api/alerts', {params: {agency}})
      .pipe(
        share(),
        tap(
          alerts => {
            this.store.dispatch(new UpdateCreditAlerts(alerts, agency));
          },
        ),
      );
  }

  /*
   * REPORT HISTORY
   */

  /**
   * Pull report history from the state.  If no agency is supplied, the one selected in the state will be used.
   */
  getHistory(agency: Agency | null = null): Observable<ReportHistoryInterface[]> {
    if (agency == null) {
      agency = this.getSelectedAgencySnapshot();
    }

    return this.store.select(CreditReportState.getHistory(agency));
  }

  /**
   * Fetch report history from the server and store them in the state.  If no agency specified, the one selected in the state will be used.
   */
  fetchHistory(agency: Agency|null = null): Observable<ReportHistoryInterface[]> {
    if (agency == null) {
      agency = this.getSelectedAgencySnapshot();
    }

    if (agency === Agency.NONE) {
      return of([]);
    }

    return this.http
      .get<ReportHistoryInterface[]>('/api/report/history', {params: {agency}})
      .pipe(
        share(),
        tap(
          history => {
            this.store.dispatch(new UpdateReportHistory(history, agency));
          },
        ),
      );
  }

  /*
   * DISPUTES
   */

  /**
   * Note that only Transunion has a read API for disputes.  EQ will always be empty.
   */
  getDisputes(agency: Agency | null = null): Observable<TransunionDispute[]> {
    if (agency == null) {
      agency = this.getSelectedAgencySnapshot();
    }

    return this.store.select(CreditReportState.getDisputes(agency));
  }

  /**
   * Note that only Transunion has a read API for disputes.  EQ will always be empty.
   */
  fetchDisputes(agency: Agency | null = null): Observable<TransunionDispute[]> {
    if (agency == null) {
      agency = this.getSelectedAgencySnapshot();
    }

    if (agency !== Agency.TRANSUNION) {
      return of([]);
    }

    return this.http
      .get<TransunionDispute[]>('/api/disputes', {params: {agency}})
      .pipe(
        share(),
        tap(
          disputes => {
            this.store.dispatch(new UpdateDisputes(disputes, agency));
          },
        ),
      );
  }

  /*
   * SELECTED AGENCY
   */

  /**
   * Get the selected agency at the current time
   */
  getSelectedAgencySnapshot(): Agency {
    return this.store.selectSnapshot(state => state.creditreport.selectedAgency);
  }

  getSelectedAgency(): Observable<Agency> {
    return this.store.select(state => state.creditreport.selectedAgency);
  }

  selectAgency(agency: Agency) {
    return this.store.dispatch(new SelectAgency(agency));
  }

  getSelectedAgencyName(): Observable<string> {
    return this.store
      .select(state => state.creditreport.selectedAgency)
      .pipe(map((agency: Agency) => {
        switch (agency) {
          case Agency.TRANSUNION:
            return 'Transunion';
          case Agency.EQUIFAX:
            return 'Equifax';
          default:
            return 'Unknown';
        }
      }));
  }
}

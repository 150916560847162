import {Component, Input} from '@angular/core';
import {RatingCommon} from 'src/app/shared/models/report/common/rating-common';

@Component({
  selector: 'app-credit-score-rating-bar',
  templateUrl: './credit-score-rating-bar.component.html',
  styleUrls: ['./credit-score-rating-bar.component.scss'],
})
export class CreditScoreRatingBarComponent {

  @Input()
  public rating: RatingCommon = {
    RatingValue: 0,
    Score: 0,
    ScoreMax: 0,
  };

}

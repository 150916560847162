import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AccountService} from 'src/app/shared/services/account.service';
import {IAccount} from 'src/app/shared/models/account';
import {ToastService} from 'src/app/core/components/toasts/toast.service';
import {UserStatus} from 'src/app/shared/models/user-status';
import {RegisterResult} from 'src/app/shared/models/register-result';
import {KbaOutcome} from 'src/app/shared/models/kba-outcome';
import {Subscription} from 'rxjs';
import PCAService from '../../../../../core/services/pca/PCAService';
import PCAAddress from '../../../../../core/services/pca/PCAAddress';
import AddressFormatter from '../../../../../core/services/pca/AddressFormatter';
import {UkcAddress} from 'src/app/core/services/pca/UkcAddress';
import {take} from 'rxjs/operators';
import {MixpanelService} from 'src/app/shared/mixpanel/mixpanel.service';
import {KbaQuestionnaire} from 'src/app/shared/models/register-result.interface';

@Component({
  selector: 'app-broken-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {

  @ViewChild('pleaseWait', {static: true}) pleaseWaitTemplate: TemplateRef<any>;
  @ViewChild('addressStep') addressStepTemplate: TemplateRef<any>;
  @ViewChild('kbaStep') kbaStepTemplate: TemplateRef<any>;
  public currentTemplate = null;

  public account: IAccount;
  public kbaQuestions: KbaQuestionnaire = null;
  public answers = {};
  public registering = false;
  public submitting = false;
  private selectAccount: Subscription;
  private pcaSubscription: Subscription;
  private chosenAddress: UkcAddress = null;
  public chosenAddressFormatted = '';

  constructor(
    private activeModal: NgbActiveModal,
    private accountService: AccountService,
    private toastService: ToastService,
    // PCAService MUST be injected here.  Its constructor sets up listeners for events
    // on the base library.  This is only done if the service injector creates it.
    private pcaService: PCAService) {
  }


  ngOnInit() {
    this.currentTemplate = this.pleaseWaitTemplate;

    this.selectAccount = this.accountService
      .getAccount()
      .pipe(take(1))
      .subscribe(account => {
        this.account = account;

        if (this.currentTemplate === null && this.needsAddressStep()) {
          this.goToAddressStep();
        } else {
          this.register();
        }
      });

    this.listenForPcaAddressChange();
  }

  close(status: string): void {
    this.activeModal.close(status);
  }

  dismiss(): void {
    this.accountService
      .recordClickLog('KBA questions', 'Skip')
      .subscribe(() => {
        this.activeModal.dismiss('skipped');
      });
  }

  register(): void {
    this.registering = true;

    // this.bb.blackbox is the string to send to register on server side
    this.accountService.registerAtTransunion(this.chosenAddress).subscribe({
      next: kba => {
        if (kba.hasOwnProperty('message') && kba.message === 'Already registered') {
          this.toastService.warning('Already completed registration');
        }

        MixpanelService.track('Broken enrollment: register at agency returned', {result: kba.result});

        switch (kba.result) {
          case RegisterResult.SUCCESS:
            this.accountService.setUserStatus(UserStatus.Active);
            this.close(UserStatus.Active);
            break;

          case RegisterResult.KBA:
            this.currentTemplate = this.kbaStepTemplate;
            this.kbaQuestions = kba.kba_questions;
            this.answers = this.kbaQuestions.getEmptyAnswerObject();
            break;

          case RegisterResult.THIN_FILE:
            this.accountService.setUserStatus(UserStatus.ThinFile);
            this.close(UserStatus.ThinFile);
            break;

          case RegisterResult.KBA_FAILED:
            // This will only happen on this step if they've already tried KBA and failed previously.  They can't try KBA twice, so this
            // status is returned.
            this.accountService.setUserStatus(UserStatus.Manual);
            this.close(UserStatus.Manual);
            break;

          case RegisterResult.EXCEPTION:
            // This should be a recoverable error, such as the phone number is in the wrong format.  This should never happen, as at this
            // point all our PII data form validation has already happened at sign up.  If this DOES happen, we could show a form to the
            // user to double-check their data.  It would have to be paired with the error message from TU so they know what to fix.
            // For now, since we have no recovery mechanism, just treat it as no match.
            this.close(UserStatus.Cancelled);
            break;

          default:
            if (kba.hasOwnProperty('reason') && kba.reason === 'Velocity checks failed therefore we cannot proceed with the application.') {
              this.close('velocity-check');
            } else {
              // Decline before KBA is considered a "no match" at the data provider.
              this.close(UserStatus.Cancelled);
            }
        }
      },
      error: () => {
        this.toastService.error('Please try again later', 'A problem has occurred', false);
        this.registering = false;
      }
    });
  }

  answer(): void {
    this.submitting = true;

    Object.keys(this.answers).map(key => {
      if (this.answers[key] === '') {
        this.submitting = false;
      }
    });

    if (!this.submitting) {
      this.toastService.warning('Please finish answering all the questions');
      return;
    }

    this.accountService
      .recordClickLog('KBA questions', 'Submit')
      .subscribe(() => {
      });

    this.accountService.answerKba(this.answers).subscribe({
      next: kba => {
        if (kba.hasOwnProperty('message')) {
          this.toastService.error(kba.message, 'A problem has occurred', false);
        } else {
          if (kba.hasOwnProperty('outcome')) {
            MixpanelService.track('Broken enrollment: answer KBA returned', {result: kba.outcome});

            if (kba.outcome === KbaOutcome.POSITIVE) {
              this.accountService.setUserStatus(UserStatus.Active);
              this.toastService.success('Success!  Your account has been activated.  Your report data will be fetched soon.');
              this.close(UserStatus.Active);

            } else if (kba.outcome === KbaOutcome.NEGATIVE_WITH_RETRY) {
              // set new kba questions
              this.kbaQuestions = kba.kba_questions;
              this.answers = this.kbaQuestions.getEmptyAnswerObject();
              this.toastService.warning('Please answer these additional questions', 'More Info Needed', true, 10000);
              this.submitting = false;

            } else {
              // KbaOutcome.NEGATIVE
              this.accountService.setUserStatus(UserStatus.Manual);
              this.close(UserStatus.Manual);
            }
          }
        }
      },
      error: error => {
        this.toastService.error('Please try again later', 'A problem has occurred', false);
        this.submitting = false;
      },
    });
  }

  ngOnDestroy(): void {
    this.selectAccount.unsubscribe();
    this.pcaSubscription.unsubscribe();
  }

  goToAddressStep(): void {
    this.currentTemplate = this.addressStepTemplate;

    // Wait for the template outlet to render, then have pcaService go look for the input field
    setTimeout(() => this.pcaService.load(), 50);
  }

  private listenForPcaAddressChange(): void {
    /**
     * Capture event fired by PCAService and copy the address to our form
     */
    this.pcaSubscription = this.pcaService
      .pcaChangeEvent
      .subscribe(
        (pcaResponse: PCAAddress) => {
          this.chosenAddress = AddressFormatter.translateLoqateToUkc(pcaResponse);
          this.chosenAddressFormatted = pcaResponse.Label;
        });
  }

  /**
   * If we didn't collect the address on signup, we need to collect it now.
   */
  needsAddressStep(): boolean {
    // They had no address at signup AND they haven't updated their address yet
    return !!this.account.settings.noAddressOnSignup && !this.account.settings.addressUpdatedSinceSignup;
  }
}

import {CreditReportInterface} from 'src/app/shared/models/report/credit-report.interface';
import {Agency} from 'src/app/shared/models/agency';
import {TransunionDispute} from 'src/app/modules/disputes/models/transunion/transunion-dispute';
import {ReportHistoryInterface} from 'src/app/shared/models/report-history.interface';
import {CreditAlerts} from 'src/app/modules/alerts/models/credit-alerts';

export class SelectAgency {
  static readonly type = '[CreditReport] Select Agency';

  constructor(public readonly agency: Agency) {
  }
}

export class UpdateReport {
  static readonly type = '[CreditReport] Update';

  constructor(public readonly report: CreditReportInterface) {
  }
}

export class UpdateCreditAlerts {
  static readonly type = '[CreditReport] Update Alerts';

  constructor(public readonly alerts: CreditAlerts, public readonly agency: Agency) {
  }
}

export class UpdateDisputes {
  static readonly type = '[CreditReport] Update Disputes';

  constructor(public readonly disputes: TransunionDispute[], public readonly agency: Agency) {
  }
}

export class UpdateReportHistory {
  static readonly type = '[CreditReport] Update History';

  constructor(public readonly history: ReportHistoryInterface[], public readonly agency: Agency) {
  }
}

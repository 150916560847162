import {Inject, Pipe, PipeTransform} from '@angular/core';
import {APP_CONFIG} from 'src/app/shared/config/app.config';
import {IAppConfig} from 'src/app/core/config/app-config.interface';
import {DateTime} from 'luxon';

/**
 *  Pipe that defaults to our AppConfig settings
 *  @see AppConfig
 */

@Pipe({
  name: 'dateTimeFormat',
  standalone: true,
})
export class DateTimeFormatPipe implements PipeTransform {
  constructor(
    @Inject(APP_CONFIG) public config: IAppConfig,
  ) {
  }

  transform(date: any, format: string = this.config.dateFormat): any {
    if (date) {
      if (format === 'withtime') {
        format = this.config.dateTimeFormat;
      }

      return DateTime.fromISO(date, {
        zone: this.config.timezone,
        locale: 'en-GB',
      }).toFormat(format);
    }
  }
}

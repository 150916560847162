import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {IAccount} from 'src/app/shared/models/account';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CarMoneyApplicationRequest} from './models/car-money-application-request';
import {CarMoneyService} from './services/car-money.service';
import {Applicant} from './models/applicant.model';
import {CarMoneyApplicationResponse} from './models/car-money-application-response';
import {HttpErrorResponse} from '@angular/common/http';
import {CreditReportService} from 'src/app/shared/services/credit-report.service';
import {ISummaryStats} from 'src/app/shared/models/report/common/i-summary-stats';
import {TrustpilotService} from '../../../trustpilot/trustpilot.service';
import {Store} from '@ngxs/store';
import {SessionState} from 'src/app/shared/state/session/session.state';
import {Subscription} from 'rxjs';
import {MixpanelService} from 'src/app/shared/mixpanel/mixpanel.service';
import {CreditReport} from 'src/app/shared/models/report/credit-report';

@Component({
  selector: 'app-car-money',
  templateUrl: './car-money.component.html',
  styleUrls: ['./car-money.component.scss']
})
export class CarMoneyComponent implements OnInit, OnDestroy {

  public account: IAccount;
  public summaryStats: ISummaryStats;
  public carMoneyForm: UntypedFormGroup;
  public isSubmitted = false;
  private selectAccount: Subscription;
  private selectReport: Subscription;

  public vehicleTypes = [
    'Car',
    'Van',
    'Motorbike',
    'Caravan',
    'MotorHome',
    'HGV',
    'Static Caravan',
    'Other'
  ];

  public drivingLicenseTypes = [
    'Full UK',
    'Provisional UK',
    'CBT', 'A2',
    'Full A Class', 'European',
    'International',
    'None'
  ];

  public maritalStatuses = [
    'Single',
    'Cohabiting',
    'Married',
    'Separated',
    'Divorced',
    'Widowed',
    'Civil Partnership',
    'Dissolved Civil Partnership'
  ];

  // public nationalities = Object.values(NationalityCodesEnum);

  /**
   * Server side validation errors
   */
  public errors = {};

  /**
   * View State
   */
  public templateName;

  @ViewChild('cm_form_view', {static: true}) formTemplate: TemplateRef<any>;
  @ViewChild('cm_accepted_view', {static: true}) appAcceptedTemplate: TemplateRef<any>;
  @ViewChild('cm_not_qualified_view', {static: true}) notQualifiedTemplate: TemplateRef<any>;
  @ViewChild('cm_app_error', {static: true}) appErrorTemplate: TemplateRef<any>;

  constructor(
    private store: Store,
    private formBuilder: UntypedFormBuilder,
    private reportService: CreditReportService,
    private carMoneyService: CarMoneyService,
    private trustpilot: TrustpilotService
  ) {
    this.initializeForm();
  }

  ngOnInit() {
    this.templateName = this.formTemplate;

    this.selectAccount = this.store.select(SessionState.getAccount)
      .subscribe((account: IAccount) => {
        this.account = account;
        this.loadFormValues();
      });

    this.selectReport = this.reportService
      .get()
      .subscribe((creditReport: CreditReport) => {
        this.summaryStats = creditReport.getSummaryStats();
      });
  }

  loadFormValues(): void {
    this.carMoneyForm.controls['forename'].setValue(this.account.user.fname);
    this.carMoneyForm.controls['surname'].setValue(this.account.user.lname);
    this.carMoneyForm.controls['email'].setValue(this.account.user.email);
    this.carMoneyForm.controls['mobile'].setValue(this.account.user.phone);
  }

  private initializeForm(): void {

    this.carMoneyForm = this.formBuilder.group({
      amountToBorrow: ['', [
        Validators.required,
      ]],
      vehicleType: ['Car', [
        Validators.required,
      ]],
      forename: ['', [
        Validators.required
      ]],
      surname: ['', [
        Validators.required
      ]],
      mobile: ['', [
        Validators.required
      ]],
      email: ['', [
        Validators.required
      ]],
      drivingLicenseType: ['', [
        Validators.required
      ]],
      maritalStatus: ['', [
        Validators.required
      ]],
      addresses: this.formBuilder.array([]),
      employments: this.formBuilder.array([]),
    });
  }

  save() {
    this.isSubmitted = true;
    this.errors = {};

    if (this.carMoneyForm.dirty && this.carMoneyForm.valid) {
      const carMoneyApplicationRequest: CarMoneyApplicationRequest = new CarMoneyApplicationRequest();
      carMoneyApplicationRequest.AmountToBorrow = this.carMoneyForm.controls['amountToBorrow'].value;
      carMoneyApplicationRequest.VehicleType = this.carMoneyForm.controls['vehicleType'].value;
      carMoneyApplicationRequest.CreditScore = this.summaryStats.score;

      const applicant: Applicant = new Applicant();
      applicant.Surname = this.carMoneyForm.controls['surname'].value;
      applicant.Forename = this.carMoneyForm.controls['forename'].value;
      applicant.Email = this.carMoneyForm.controls['email'].value;
      applicant.Mobile = this.carMoneyForm.controls['mobile'].value;
      applicant.MaritalStatus = this.carMoneyForm.controls['maritalStatus'].value;
      applicant.DrivingLicenseType = this.carMoneyForm.controls['drivingLicenseType'].value;
      applicant.Addresses = this.carMoneyForm.controls['addresses'].value;
      applicant.Employment = this.carMoneyForm.controls['employments'].value;
      carMoneyApplicationRequest.Applicants.push(applicant);

      this.carMoneyService.postApplication(carMoneyApplicationRequest)
        .subscribe({
          next: (carMoneyApplicationResponse: CarMoneyApplicationResponse) => {
            // If pre-qualifications met, application is accepted
            if (carMoneyApplicationResponse.isAccepted) {
              this.templateName = this.appAcceptedTemplate;
              MixpanelService.track('SuccessfulSubmit car money offer');
              this.trustpilot.open();
            } else {
              this.templateName = this.notQualifiedTemplate;
            }
          },
          error: error => {
            if (error.status === 422) {
              this.extractLaravelValidationErrors(error);
            } else {
              // CarMoney specific errors are logged on the server.
              this.templateName = this.appErrorTemplate;
            }
          },
        });
    }
  }

  extractLaravelValidationErrors(httpErrorResponse: HttpErrorResponse) {
      Object.keys(httpErrorResponse.error.errors).forEach(fieldName => {
        // Normalize fieldnames by removing the Applicants wrapper
        const normalName = fieldName.replace('Applicants.0.', '');
        this.errors[normalName] = httpErrorResponse.error.errors[fieldName];
      });
  }

  hasError(fieldName, errorName) {
    return (this.formControls && this.formControls[fieldName].touched && this.formControls[fieldName].hasError(errorName));
  }

  get formControls() {
    return (this.carMoneyForm) ? this.carMoneyForm.controls : null;
  }

  ngOnDestroy(): void {
    this.selectAccount.unsubscribe();
    this.selectReport.unsubscribe();
  }
}

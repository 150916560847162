import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {RatingCommon} from "src/app/shared/models/report/common/rating-common";

@Component({
  selector: 'app-how-to',
  templateUrl: './how-to.component.html',
  styleUrls: ['./how-to.component.scss']
})
export class HowToComponent {

  rating: RatingCommon = {
    RatingValue: 4,
    Score: 682,
    ScoreMax: 710,
  };

  today = '';

  constructor(public activeModal: NgbActiveModal) { }

  close() {
    this.activeModal.close();
  }
}

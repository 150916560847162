<div class="intro-modal-backdrop">&nbsp;</div>

<div class="modal-body">

<!--  <div class="p-2 p-sm-4 intro-container">-->

    <div class="intro-page pt-2 pb-5 mx-auto">

<!--      <div class="text-end pt-2 pb-3">-->
<!--        <i class="fa-lg fa-regular fa-circle-xmark close clickable pe-3" data-dismiss="modal" aria-label="Close" (click)="close()"></i>-->
<!--      </div>-->


      <!-- Brand Logo -->
      <div class="d-flex flex-row justify-content-between align-items-center">
        <div class="intro-logo">
          <img class="mx-auto d-inline-block" appBrandImage="assets/images/-brand-/LGO_UKC_Black.svg" alt="Portal Home"/>

          <div role="tooltip" id="ngb-popover-0" class="popover fade ng-star-inserted show bs-popover-bottom"
               style="position: absolute; inset: 0px auto auto 40px; margin: 0px; transform: translate(-40px, 40px);" data-popper-placement="bottom">
            <div data-popper-arrow="" class="popover-arrow" style="position: absolute; left: 30px;"></div>
            <div class="popover-body p-1 p-sm-2 p-md-3">Return to Start by clicking our logo</div>
          </div>
        </div>

        <div class="intro-menu">
          <span class="badge rounded-pill bg-primary p-2 text-white float-end"><i class="fas fa-bars me-1"></i> MENU</span>

          <div role="tooltip" id="ngb-popover-1" class="popover fade ng-star-inserted show bs-popover-bottom"
               style="position: absolute; inset: 50px 0px auto auto; margin: 0px; " data-popper-placement="bottom">
            <div data-popper-arrow="" class="popover-arrow" style="position: absolute; right: 30px;"></div>
            <div class="popover-body p-1 p-sm-2 p-md-3">Tap here to access all our services</div>
          </div>
        </div>
      </div>


      <!-- Score -->
      <div class="d-grid col-12 mx-auto intro-score-container">

        <div role="tooltip" id="ngb-popover-2" class="popover fade ng-star-inserted show bs-popover-top mx-auto"
             style="position: relative" data-popper-placement="top">
          <div data-popper-arrow="" class="popover-arrow" style="position: absolute; right: auto; left: 50%"></div>
          <div class="popover-body p-1 p-sm-2 p-md-3">Your Live Credit Score shows here</div>
        </div>

        <div class="intro-score">682<sup>*</sup></div>
      </div>


      <!-- Credit Rating -->
        <div class="row mb-3">

          <div class="col-sm-12 col-md-3 ps-3 pt-1">
            <div class="popover bs-popover-auto fade show d-none d-sm-none d-md-inline-block float-end" role="tooltip" style="position: relative;" data-popper-placement="left">
              <div class="popover-arrow" style="position: absolute; bottom: 10px"></div>
              <div class="popover-body py-2 px-1 px-sm-1 px-md-1 px-lg-2">Your Credit Rating shows here</div>
            </div>
          </div>

          <div class="col-sm-12 col-md-6">
            <app-credit-score-progress-bar [rating]="rating" [reportDate]="today"></app-credit-score-progress-bar>

            <app-credit-score-rating-bar [rating]="rating"></app-credit-score-rating-bar>
          </div>

          <div class="col-sm-12 col-md-3 pt-3 ps-3">
            <div class="popover bs-popover-auto fade show d-sm-inline-block d-md-none mb-3" role="tooltip" style="position: relative;" data-popper-placement="bottom">
              <div class="popover-arrow" style="position: absolute; left: 75%"></div>
              <div class="popover-body py-2 px-1 px-sm-1 px-md-1 px-lg-2">Your Credit Rating shows here</div>
            </div>
          </div>
        </div>



      <!-- Feature Buttons -->
       <div class="d-flex flex-row justify-content-center align-items-center feature-buttons">
         <div class="outer">
            &nbsp;
         </div>

         <div class="inner">
           <div class="d-grid gap-2">
             <span class="badge rounded-pill border border-2 border-dark py-2 px-1 text-dark">Dark Web Monitoring <i class="far fa-eye mx-3 font-ukc-blue"></i> Active</span>
             <span class="badge rounded-pill border border-2 border-dark py-2 px-1 text-dark">Perks & Discounts: <i class="far fa-eye mx-3 font-ukc-blue"></i> Active</span>
           </div>

         </div>

         <div class="outer ps-3">
           <div class="popover bs-popover-auto fade show d-none d-sm-none d-md-inline-block" role="tooltip"
                style="position: relative;"
                data-popper-placement="right">
             <div class="popover-arrow" style="position: absolute; top: 0px; transform: translate(0px, 19px);"></div>
             <div class="popover-body p-1 p-sm-1 p-md-1 p-lg-2">Tap to Fast Track your Dark Web results and Perks & Discounts</div>
           </div>
         </div>
       </div>

      <div class="pt-2 pe-2 clearfix">
        <div class="popover bs-popover-auto fade show d-sm-inline-block d-md-none float-end" role="tooltip"
             style="position: relative;"
             data-popper-placement="bottom">
          <div data-popper-arrow="" class="popover-arrow" style="position: absolute; left: 30px;"></div>
          <div class="popover-body p-1 p-sm-1 p-md-1 p-lg-2">Tap to Fast Track your Dark Web results and Perks & Discounts</div>
        </div>
      </div>


      <!-- Summary Section -->
      <div class="ss-pop-container mt-4 ps-5">
        <div class="popover bs-popover-auto fade show" role="tooltip"
             style="position: relative;"
             data-popper-placement="top">
          <div class="popover-arrow" style="position: absolute; bottom: -10px; right: 50%"></div>
          <div class="popover-body p-1 p-sm-1 p-md-1 p-lg-2">Your financial summary sits below this line</div>
        </div>
      </div>

      <div class="d-flex flex-row justify-content-between align-items-center intro-summary-sec mb-5 mt-2 py-2">
        <div class="d-flex flex-column justify-content-start align-items-center flex-fill">
          <h4 class="pt-2">682</h4>
          <p>Credit Score</p>
        </div>

        <div class="d-flex flex-column justify-content-start align-items-center flex-fill">
          <h4 class="pt-2">100%</h4>
          <p>Payment History</p>
        </div>

        <div class="d-flex flex-column justify-content-start align-items-center flex-fill">
          <h4 class="pt-2">1</h4>
          <p>Negative Marks</p>
        </div>

        <div class="d-flex flex-column justify-content-start align-items-center flex-fill">
          <h4 class="pt-2">3</h4>
          <p>Hard Inquiries</p>
        </div>

        <div class="d-flex flex-column justify-content-start align-items-center flex-fill">
          <h4 class="pt-2">100%</h4>
          <p>Credit Card Utilisation</p>
        </div>

        <div class="d-flex flex-column justify-content-start align-items-center flex-fill">
          <h4 class="pt-2">8</h4>
          <p>Open Disputes</p>
        </div>
      </div>


      <div class="d-grid col-10 mx-auto">
        <p class="text-center">&ast;<span class="small">Scores displayed in this introduction are for sample purposes only.
          Please click continue to see your actual score.</span>
        </p>
      </div>
      <div class="d-grid col-8 col-sm-6 col-md-6 col-lg-4 mx-auto mt-2 mb-3">
        <button class="btn btn-lg btn-primary px-4 rounded-pill btn-lg" (click)="close()">Continue</button>
      </div>

     </div>
<!--  </div>-->

</div>

import {Component} from '@angular/core';
import {CreditReportService} from 'src/app/shared/services/credit-report.service';
import {Observable} from 'rxjs';
import {Agency} from 'src/app/shared/models/agency';
import {AsyncPipe} from '@angular/common';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-your-file-explained',
  templateUrl: './your-file-explained.component.html',
  styleUrls: ['../../nav.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
  ],
})
export class YourFileExplainedComponent {
  url$: Observable<string>;
  agencies = Agency;

  constructor(reportService: CreditReportService) {
    this.url$ = reportService
      .getSelectedAgency()
      .pipe(
        map(agency => {
          if (agency === Agency.TRANSUNION) {
            return '/assets/tu-credit-file-explained.pdf';
          } else if (agency === Agency.EQUIFAX) {
            return '/assets/eq-credit-file-explained.pdf';
          }

          return '';
        })
      );
  }
}

import {Equifax} from "../equifax";
import Name = Equifax.Name;

export interface NameMatch {
  nameMatchStatus: string;
  dob?: string;
  name: Equifax.Name;
}


export class NameMatchStat {
  count: number;
  nameMatch: NameMatch;

  constructor(nameMatch: NameMatch) {
    this.nameMatch = nameMatch;
    this.count = 1;
  }

  increment() {
    this.count++;
  }
}


export class DistinctNames {
  public names: Array<NameMatchStat> = [];

  constructor() {

  }

  getMostUsed(): NameMatchStat {
    let searchIndex = 0;

    for (let i = 1; i < this.names.length; i++) {
      if (this.names[i].count > this.names[searchIndex].count) {
        searchIndex = i;
      }
    }

    return this.names[searchIndex];
  }


  addNameOrUpdateCount(searchName:  NameMatch): boolean {
    let isFound = false;

    for (let i = 0; i < this.names.length; i++) {
      if (this.isNameMatch(searchName.name, this.names[i].nameMatch.name)) {
        isFound = true;
        this.names[i].increment();
        if (!this.names[i].nameMatch.dob) { // Not all have DOB, so backfill from another match if missing
          this.names[i].nameMatch.dob = searchName.dob;
        }
        break;
      }
    }

    if (!isFound) {
      this.names.push(new NameMatchStat(searchName));
    }

    return isFound;
  }

  isNameMatch(name: Equifax.Name, name2: Equifax.Name): boolean {
    return ((name.title === name2.title) && (name.forename === name2.forename)
      && (name.middleName === name2.middleName) && (name.surname === name2.surname));
  }
}

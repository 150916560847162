import {Directive, ElementRef, OnInit, Input, OnChanges} from '@angular/core';
import {BrandService} from '../services/brand.service';

/**
 * BrandImageDirective
 *
 * Used to get a brand specific image by extracting brand from url and setting image path to the specific brand
 */
@Directive({
  selector: '[appBrandImage]',
  standalone: true,
})
export class BrandImageDirective implements OnInit, OnChanges {

  @Input('appBrandImage') src = '';

  constructor(
    private elementRef: ElementRef,
    private brandService: BrandService
  ) {}

  ngOnInit() {
    const brand = this.brandService.get();
    this.elementRef.nativeElement.src = this.src.replace('-brand-', brand);
  }


  ngOnChanges(): void {
    const brand = this.brandService.get();
    this.elementRef.nativeElement.src = this.src.replace('-brand-', brand);
  }
}

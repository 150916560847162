import {CreditReportInterface} from 'src/app/shared/models/report/credit-report.interface';
import {Agency} from 'src/app/shared/models/agency';
import {TransunionRawReport} from 'src/app/shared/models/report/transunion/transunion-raw-report';
import {ISummaryStats} from 'src/app/shared/models/report/common/i-summary-stats';
import {AccountType} from 'src/app/shared/models/report/account-type.enum';
import {NegativeMarksSummary} from 'src/app/shared/models/report/negative-marks-summary';
import {EquifaxRawReport} from 'src/app/shared/models/report/equifax/equifax-raw-report';
import {SearchInquirySummarized} from 'src/app/shared/models/report/common/search-inquiry-summarized';
import {ElectoralRollCommon} from "./common/electoral-roll-common";
import {RatingCommon} from "./common/rating-common";
import {NegativeMarkSummarized} from 'src/app/shared/models/report/common/negative-mark-summarized';
import {NegativeMarkType} from 'src/app/shared/models/report/common/negative-mark-type.enum';
import {JudgementCommon} from 'src/app/shared/models/report/common/judgement-common';
import {FinancialAccountSummarized} from 'src/app/shared/models/report/common/financial-account-summarized';
import {AddressLinksCommon} from "./common/address-links-common";
import {FinancialAccountCommon} from 'src/app/shared/models/report/common/financial-account-common';
import {InsolvencyCommon} from 'src/app/shared/models/report/common/insolvency-common';
import {PersonalInformationCommon} from "./common/personal-information-common";
import {FinancialConnectionCommon} from "./common/financial-connection-common";
import {CifasCaseCommon} from "./common/cifas-case-common";
import {NoticeCommon} from 'src/app/shared/models/report/common/notice-common';


/**
 * This is the full data file we have on the customer.  That includes the "Report" which is merely
 * the unmodified data from the agency.  It also includes a lot of summary and analysis alongside
 * it.
 */
export abstract class CreditReport implements CreditReportInterface {

  guid = '';
  agency = Agency.NONE;
  report: EquifaxRawReport | TransunionRawReport;
  summary: ISummaryStats;
  financialAccounts: Array<FinancialAccountSummarized> = [];
  financialConnections: Array<FinancialConnectionCommon> = [];
  negativeMarks: Array<NegativeMarkSummarized> = [];
  /**
   * Hard inquries
   */
  inquiries: Array<SearchInquirySummarized> = [];
  softInquiries: Array<SearchInquirySummarized> = [];

  /**
   * Indicates report fetching failed
   */
  reportUnavailable = false;
  /**
   * Indicates report will be empty due to account not having a report yet.
   */
  mvOrThinfile = false;
  locallyCached = false;

  // Standardized fields.  These are built in the portal
  judgementsCommon: JudgementCommon[] = [];
  insolvenciesCommon: InsolvencyCommon[] = [];
  cifasCommon: CifasCaseCommon[] = [];
  creditCardsCommon: FinancialAccountCommon[] = [];
  closedAccountsCommon: FinancialAccountCommon[] = [];
  mortgagesCommon: FinancialAccountCommon[] = [];
  otherAccountsCommon: FinancialAccountCommon[] = [];
  personalLoansCommon: FinancialAccountCommon[] = [];
  electoralRollCommon: ElectoralRollCommon = null;
  noticesOfCorrectionCommon: NoticeCommon[];
  //financialConnectionsCommon: FinancialConnection
  // //TODO: Need to return array of the one ER item from Agency TU
  // electoralRoll: Array<ElectoralRoll> = [];
  addressLinksCommon: AddressLinksCommon = null;

  protected constructor(obj?: Partial<CreditReportInterface>) {
    if (!obj) {
      return;
    }

    Object.assign(this, obj);
  }

  /**
   * Credit report can be empty if thin file or manual verify
   */
  isEmpty(): boolean {
    return this.guid === '';
  }

  getGuid(): string {
    return this.guid;
  }

  getRawReport(): EquifaxRawReport | TransunionRawReport {
    return this.report;
  }

  getSummaryStats() {
    return this.summary;
  }

  getAddressLinks(): AddressLinksCommon {
    return this.addressLinksCommon;
  }

  getFinancialAccountInformation(accountType: AccountType): FinancialAccountCommon[] {
    let accts: FinancialAccountCommon[];

    switch (accountType) {
      case AccountType.ClosedAccounts:
        accts = this.closedAccountsCommon;
        break;
      case AccountType.CreditCards:
        accts = this.creditCardsCommon;
        break;
      case AccountType.Mortgages:
        accts = this.mortgagesCommon;
        break;
      case AccountType.PersonalLoans:
        accts = this.personalLoansCommon;
        break;
      case AccountType.OtherAccounts:
        accts = this.otherAccountsCommon;
        break;
      default:
        throw new Error('Invalid account type');
    }

    return accts;
  }

  /**
   * Find and return a common version of a financial account
   */
  getAccount(accountType: AccountType, accountId: number|string): FinancialAccountCommon | null {
    return this.getFinancialAccountInformation(accountType)
      .find(acct => acct.AccountId == accountId); // Don't use ===.  The account detail resolver won't find TU accounts
  }

  /**
   * Checks each type of account, looking for a specific id.  If found, returns which
   * type of account it is.
   */
  getAccountTypeById(accountId: number|string): AccountType {
    for (let i = 0; i < 5; i++) {
      const acct = this.getAccount(i, accountId);

      if (acct) {
        return i;
      }
    }
  }

  /**
   * Common version of Judgements
   */
  getJudgments(): JudgementCommon[] {
    return this.judgementsCommon;
  }

  /**
   * Common version of Insolvencies
   */
  getInsolvencies(): InsolvencyCommon[] {
    return this.insolvenciesCommon;
  }

  getInsolvency(insolvencyOrderId: string): InsolvencyCommon | null {
    return this.getInsolvencies()
      .find(value => value['InsolvencyOrderId'] == insolvencyOrderId);
  }

  getJudgment(casePerId: string): JudgementCommon | null {
    return this.getJudgments()
      .find(value => value['CasePerId'] == casePerId);
  }

  abstract getOtherName(AliasId: number);

  getFinancialConnections(): Array<FinancialConnectionCommon> {
    return this.financialConnections;
  }

  getFinancialConnection(AssociateId: number): FinancialConnectionCommon {
    let data = null;

    this.getFinancialConnections()
      .forEach((value: FinancialConnectionCommon) => {
        if (value.AssociateId === AssociateId) {
          data = value;
        }
      });

    return data;
  }

  getCifas() {
    return this.cifasCommon;
  }

 getCifasCase(CaseId: string) {
   let data = null;

   this.cifasCommon.forEach(value => {
     if (value.CaseId == CaseId) {
       data = value;
     }
   });

   return data;
 }

  getNegativeMarksSummary(): NegativeMarksSummary {
    const marks = this.negativeMarks;
    const result: NegativeMarksSummary = {insolvencies: 0, judgements: 0, missed_payments: 0};

    for (const item of marks) {
      if (item.type === NegativeMarkType.MISSED_PAYMENT) {
        result.missed_payments++;
      }
      if (item.type === NegativeMarkType.JUDGEMENT) {
        result.judgements++;
      }
      if (item.type === NegativeMarkType.INSOLVENCY) {
        result.insolvencies++;
      }
    }

    return result;
  }

  abstract getRating(): RatingCommon;

  getElectoralRoll(): ElectoralRollCommon {
    return this.electoralRollCommon;
  }

  abstract getPersonalInformation(): PersonalInformationCommon;
}

import {ITransunionRawReport} from 'src/app/shared/models/report/transunion/i-transunion-raw-report';
import {ElectoralRollCommon} from "../common/electoral-roll-common";
import {SearchInquirySummarized} from "src/app/shared/models/report/common/search-inquiry-summarized";
import {CifasCaseCommon} from "../common/cifas-case-common";
import {FinancialAccountInformation} from "./definitions/FinancialAccountInformation";
import {Moda} from "./definitions/Moda";
import {PersonalInformation} from "./definitions/PersonalInformation";
import {PublicInformation} from "./definitions/PublicInformation";
import {ReportDetails} from "./definitions/ReportDetails";
import {SearchHistory} from "./definitions/SearchHistory";
import {Rating} from "./definitions/Rating";
import {OtherNames} from "./definitions/OtherNames";
import {FinancialConnections} from "./definitions/FinancialConnections";
import {TransunionTransformationService} from "./services/transunion-transformation-service";
import {AgencyRawReport} from 'src/app/shared/models/report/agency-raw-report';
import {Insolvency} from 'src/app/shared/models/report/transunion/definitions/Insolvency';
import {Judgment} from 'src/app/shared/models/report/transunion/definitions/Judgment';
import {AccountType} from 'src/app/shared/models/report/account-type.enum';
import {IAccount} from 'src/app/shared/models/report/i-account';

/**
 * This is the unmodified report from Transunion.
 */
export class TransunionRawReport extends AgencyRawReport implements ITransunionRawReport  {

  constructor(obj?: Partial<ITransunionRawReport>) {
    super();
    Object.assign(this, obj);
  }

  // TODO: Add Members for key portions and break them out as their own objects/interfaces as needed
  public AddressLinks: any;
  public Cifas: any;
  // public Details: any;
  public ElectoralRoll: ElectoralRollCommon;
  // public FinancialAccountInformation: IFinancialAccountInformation;
  // public FinancialConnections: any;
  // public Moda: any;
  public NoticesOfCorrection: any;
  // public OtherNames: any;
  // public PersonalInformation: any;
  // public PublicInformation: any;
  // public ReportDetails: any;
  // public Rating: Rating;
  // public SearchHistory: any;


  //AddressLinks?: AddressLinks;
  //Cifas?: Cifas;
  //ElectoralRoll?: ElectoralRoll;
  FinancialAccountInformation?: FinancialAccountInformation;
  FinancialConnections?: FinancialConnections;
  Moda?: Moda;
  //NoticesOfCorrection?: NoticesOfCorrection;
  OtherNames?: OtherNames;
  PersonalInformation?: PersonalInformation;
  PublicInformation?: PublicInformation;
  Rating?: Rating;
  ReportDetails?: ReportDetails;
  ReportId?: string;
  SearchHistory?: SearchHistory;

  /**
   * Sort inquiries by date
   * @param a
   * @param b
   */
  private static sortSearches(a, b): number {
    if (a.SearchDate > b.SearchDate) {
      return -1;
    } else if (a.SearchDate < b.SearchDate) {
      return 1;
    }
    return 0;
  }

  transform(): void {
    const transunionTransformationService = new TransunionTransformationService();

    this.Cifas = transunionTransformationService.convertCifasData(this.Cifas);
    // transunionTransformationService.convertJudgements(this);
    // transunionTransformationService.convertInsolvencies(this);
    // transunionTransformationService.convertFinancialAccounts(this);
  }

  /**
   * Get Transunion version of insolvencies
   */
  getInsolvenciesTransunion(): Insolvency[] {
    if (this.PublicInformation?.Insolvencies?.Insolvency) {
      return this.PublicInformation.Insolvencies.Insolvency;
    }

    return [];
  }

  /**
   * Get Transunion version of judgments
   */
  getJudgmentsTransunion(): Judgment[] {
    if (this.PublicInformation?.Judgments?.Judgment) {
      return this.PublicInformation?.Judgments?.Judgment;
    }

    return [];
  }

  /**
   * Get Transunion version account listing
   */
  getFinancialAccountInformationTransunion(accountType: AccountType): IAccount[] {
    let accts: IAccount[];

    switch (accountType) {
      case AccountType.ClosedAccounts:
        accts = this.FinancialAccountInformation.ClosedAccounts.Account as unknown as IAccount[];
        break;
      case AccountType.CreditCards:
        accts = this.FinancialAccountInformation.CreditCards.Account as unknown as IAccount[];
        break;
      case AccountType.Mortgages:
        accts = this.FinancialAccountInformation.Mortgages.Account as unknown as IAccount[];
        break;
      case AccountType.PersonalLoans:
        accts = this.FinancialAccountInformation.PersonalLoans.Account as unknown as IAccount[];
        break;
      case AccountType.OtherAccounts:
        accts = this.FinancialAccountInformation.OtherAccounts.Account as unknown as IAccount[];
        break;
      default:
        throw new Error('Invalid account type');
    }

    if (!accts) {
      accts = [];
    }

    return accts;
  }

  getOtherNames(): Array<any> {
    const data = this.OtherNames.Records;

    if (data.hasOwnProperty('OtherName')) {
      return data.OtherName;
    }

    return [];
  }

  getFinancialConnections(): Array<any> {
    const data = this.FinancialConnections.Records;

    if ('FinancialConnection' in data) {
      return data.FinancialConnection;
    }

    return [];
  }

  getCifas(): Array<CifasCaseCommon> {
    // const data = this.Cifas.CifasCases;
    //
    // if (data.hasOwnProperty('CifasCase')) {
    //   return data.CifasCase;
    // }

    return this.Cifas;
  }

  /**
   * Inquiries / searches on one's credit file, made by the personal in question.  "Your" searches
   * vs "other" searches.  Considered soft searches.
   */
  getPersonalSearches(): Array<SearchInquirySummarized> {
    let data = [];

    if (this.SearchHistory.CurrentAddressUserSearches.SearchRecord) {
      data = data.concat(this.SearchHistory.CurrentAddressUserSearches.SearchRecord);
    }

    if (this.SearchHistory.PreviousAddressUserSearches.SearchRecord) {
      data = data.concat(this.SearchHistory.PreviousAddressUserSearches.SearchRecord);
    }

    return data.sort(TransunionRawReport.sortSearches);
  }
}

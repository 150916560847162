import {InjectionToken} from '@angular/core';
import {IAppConfig} from '../../core/config/app-config.interface';

export const APP_CONFIG = new InjectionToken<IAppConfig>('app.config');

export const AppConfig: IAppConfig = {
  version: 'v0.0.1',
  appName: 'UKCreditratings.com',
  apiEndpoint: '',  // not used yet

  // https://moment.github.io/luxon/#/parsing?id=table-of-tokens
  dateTimeFormat: 'FF',
  dateFormat: 'DD',

  timezone: 'Europe/London',
  googleClientId: `994657562748-jgm6fut68cbpcbq91n5g9d1hcominmf5.apps.googleusercontent.com`,
};

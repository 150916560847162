import {Injectable} from '@angular/core';
import {Chainable} from '../models/chainable';
import {AccountService} from 'src/app/shared/services/account.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DarkWebOnetimeAdComponent} from './dark-web-onetime-ad.component';
import {IaoModalResultEnum} from '../iao-modal-result.enum';
import {Account} from 'src/app/shared/models/account';
import {genericErrorObserver} from 'src/app/shared/observables/generic-error.observer';
import {take} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class DarkWebOnetimeAdService implements Chainable {
  private account: Account;

  constructor(
    private accountService: AccountService,
    private modalService: NgbModal
  ) {}

  canDisplay(): boolean {
    return this.account?.settings?.hasSeenDarkWebOnetimeAd === false;
  }

  onIaoClose(): void {
    this.accountService
      .updateSetting('hasSeenDarkWebOnetimeAd', true)
      .subscribe(genericErrorObserver);
  }


  resolveDependencies(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.accountService.getAccount()
        .pipe(take(1))
        .subscribe({
          next: (account: Account) => {
            this.account = account;
            resolve();
          },
          error: (error) => {
            // Handle error
            console.error('Error fetching account information:', error);
            reject(error);
          }
        });
    });
  }

  run(): Promise<IaoModalResultEnum> {
    const modalRef = this.modalService.open(DarkWebOnetimeAdComponent, { size: 'lg' });

    if (modalRef) {
      modalRef.componentInstance.account = this.account;
    }

    return modalRef.result;
  }

}

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CTA} from 'src/app/shared/models/cta/cta.interface';
import {CtaService} from 'src/app/shared/services/cta.service';
import {CtaStatus} from 'src/app/shared/models/cta/cta-status.enum';
import {CtaActionService} from '../../services/cta-action.service';
import {ChangeCtaStatusRequest} from 'src/app/shared/models/cta/change-cta-status-request.interface';
import {AccountService} from 'src/app/shared/services/account.service';
import {Subscription} from 'rxjs';
import {Store} from '@ngxs/store';
import {SmartOfferService} from 'src/app/shared/services/smart-offer-service';
import {genericErrorObserver} from 'src/app/shared/observables/generic-error.observer';
import {IAccount} from 'src/app/shared/models/account';
import {MixpanelService} from 'src/app/shared/mixpanel/mixpanel.service';


@Component({
  selector: 'app-cta-item',
  templateUrl: './cta-item.component.html',
  styleUrls: ['./cta-item.component.scss'],
})
export class CtaItemComponent implements OnInit, OnDestroy {

  @Input() cta: CTA;

  /**
   * To access enum within template
   */
  public ctaStatus = CtaStatus;
  private selectAccount: Subscription;
  private account: IAccount;

  constructor(
    private ctaService: CtaService,
    private ctaActionService: CtaActionService,
    private accountService: AccountService,
    private store: Store,
    private smartOfferService: SmartOfferService
  ) { }

  ngOnInit() {
    this.selectAccount = this.accountService.getAccount()
      .subscribe(account => {
        this.account = account;
      });
  }

  ctaButtonClick(): void {

    // Pop Smart Offer Consent if they have not yet consented
    if (this.cta.is_offer && !this.account.settings.hasConsentedSmartOffers) {
      this.smartOfferService.openOfferConsentModal()
        .then((hasConsented: boolean) => {
          if (hasConsented) {
            this.doCtaAction();
          }
        });
    } else {
      this.doCtaAction();
    }
  }

  private doCtaAction(): void {
    // Record a CLICKED event on the CTA
    const changeCtaStatusRequest: ChangeCtaStatusRequest = {
      cta_users_id: this.cta.cta_users_id,
      status: CtaStatus.CLICKED
    };

    this.ctaService
      .updateStatus(changeCtaStatusRequest)
      .subscribe(genericErrorObserver);

    MixpanelService.track('Click CTA action', {cta_id: this.cta.id, cta_title: this.cta.title});

    // Act on the button click
    this.ctaActionService
      .handleAction(this.cta.btn_action, this.account);
  }

  /**
   * Change CTA status to completed
   */
  complete() {
    const changeCtaStatusRequest: ChangeCtaStatusRequest = {
      cta_users_id: this.cta.cta_users_id,
      status: CtaStatus.COMPLETED
    };

    this.ctaService
      .updateStatus(changeCtaStatusRequest)
      .subscribe(genericErrorObserver);

    MixpanelService.track('Click CTA complete', {cta_id: this.cta.id, cta_title: this.cta.title});
  }

  /**
   * Change CTA status to dismissed
   */
  dismiss() {
    const changeCtaStatusRequest: ChangeCtaStatusRequest = {
      cta_users_id: this.cta.cta_users_id,
      status: CtaStatus.DISMISSED
    };

    this.ctaService
      .updateStatus(changeCtaStatusRequest)
      .subscribe(genericErrorObserver);

    MixpanelService.track('Click CTA dismiss', {cta_id: this.cta.id, cta_title: this.cta.title});
  }

  ngOnDestroy(): void {
    this.selectAccount.unsubscribe();
  }
}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {CarMoneyApplicationRequest} from '../models/car-money-application-request';


@Injectable({
  providedIn: 'root'
})
export class CarMoneyService {

  constructor(private http: HttpClient) {}

  postApplication(carMoneyApplicationRequest: CarMoneyApplicationRequest): Observable<any> {
    return this.http.post('/api/offers/car-money/apply', carMoneyApplicationRequest);
  }
}

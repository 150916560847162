import {Equifax} from "../equifax";
import LinkedAddressData = Equifax.LinkedAddressData;
import {AddressDto} from "./address-dto";
import CurrentAccount = Equifax.CurrentAccount;
import MatchedResidentialStructuredAddress = Equifax.MatchedResidentialStructuredAddress;

import {AddressLinkDetailCommon} from "../../common/address-link-detail-common";
import {NoticeCommon} from "../../common/notice-common";
import {InsightAccountTypes} from "../models/insight-account-types";

export class AddressLinkDetailDto implements AddressLinkDetailCommon{

  AddressFrom: string;
  AddressLinkId: string;
  AddressTo: string;
  EarliestConfirmationOn: string;
  LastConfirmationOn: string;
  MostRecentSource: string;
  Notices: Array<NoticeCommon> = [];

  constructor(linkedAddressData: LinkedAddressData, searchedAddress: MatchedResidentialStructuredAddress) {

    this.AddressLinkId = linkedAddressData?.linkedAddress?.address?.addressID;

    // Check all accounts in insight data associated with the address and find most recent source of the address and date
    let mostRecentUpdateDate = 0;
    let earliestDate = 30001231;  // The world will end before we hit this date

    // Get all Insight properties (these will be the include account types and other properties the customer currently has. see InsightAccountContainer for types)
    let properties = [];
    if (linkedAddressData.addressSpecificData.insightData) {
      properties = Object.getOwnPropertyNames(linkedAddressData.addressSpecificData.insightData);
    }


    // Get array of all known account types to filter against from the InsightAccountTypes Enum
    // e.g. ['bankDefaultAgreement','budgetAccount','chargeCard','commsSupplyAccount', ...]
    const insightAccountTypes = Object.getOwnPropertyNames(InsightAccountTypes)
      .filter((property) => isNaN(parseInt(property))); //filters the enum values 0, 1, 2 that also come back from with the names

    // Iterate over all insight properties, skipping over non account properties
    properties.forEach((property: string) => {
      // Make sure we are dealing with a account type as there may be other non account properties we are iterating over
      if (insightAccountTypes.includes(property)) {
        linkedAddressData.addressSpecificData.insightData[property].forEach((currentAccount: CurrentAccount) => {
          const lastDate = parseInt(currentAccount.lastUpdateDate.replace('-', ''));

          // Find latest date address was confirmed
          if (lastDate > mostRecentUpdateDate) {
            mostRecentUpdateDate = lastDate;
            this.LastConfirmationOn = currentAccount.lastUpdateDate;
            this.MostRecentSource = currentAccount.companyName
          }

          // Find the earliest date for address
          const startDate = parseInt(currentAccount.startDate.replace('-', ''));
          if(startDate < earliestDate) {
            earliestDate = startDate
            this.EarliestConfirmationOn = currentAccount.startDate;
          }
        });
      }
    });

    //Use linkType to determine TO/FROM address
    switch (linkedAddressData.linkType) {
      // Indicates that the linked address is AFTER the address that was searched on
      case 'nextFromSuppliedCurrent': {
        this.AddressFrom = new AddressDto(searchedAddress).Address;
        this.AddressTo = new AddressDto(linkedAddressData.linkedAddress.address).Address;
        break;
      }
      // No definition given, but sounds like previous address to a previous address (needs update)
      case 'previousFromSuppliedPrevious': {
        this.AddressFrom = new AddressDto(linkedAddressData.linkedAddress.address).Address;
        this.AddressTo = new AddressDto(searchedAddress).Address;
        break;
      }
      // Indicates that the linked address is BEFORE
      case 'previousFromSuppliedCurrent': {
        this.AddressFrom = new AddressDto(linkedAddressData.linkedAddress.address).Address;
        this.AddressTo = new AddressDto(searchedAddress).Address;
        break;
      }
      default: {
        this.AddressFrom = null;
        this.AddressTo = null;
        break;
      }
    }
  }
}

import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ProductInterface} from 'src/app/shared/models/product.interface';

@Component({
  selector: 'app-fast-perks',
  templateUrl: './fast-perks.component.html',
  styleUrls: ['./fast-perks.component.scss'],
})
export class FastPerksComponent {

  public product: ProductInterface;
  public fastPerksUrl = null;

  constructor(public activeModal: NgbActiveModal) {}
}

export enum InsightAccountTypes {
  'bankDefaultAgreement',
  'budgetAccount',
  'chargeCard',
  'commsSupplyAccount',
  'consolidatedDebt',
  'councilArrears',
  'creditCard',
  'currentAccount',
  'fixedTermAgreement',
  'hirePurchase',
  'homeLendingAgreement',
  'insuranceAgreement',
  'mailOrderAccount',
  'optionAccount',
  'publicUtilityAccount',
  'rentalAgreement',
  'securedLoan',
  'studentLoan',
  'uncategorisedAgreement',
  'unpresentableCheque',
  'unsecuredLoan',
  'xmasClub',
  'payDayOrShortTermLoan',
  'buyToLetMortgage',
  'propertyRental',
  'basicBankAccount',
  'bridgingFinance',
  'greenDeals',
  'socialHousingRental',
  'localAuthorityHousing'
}

import {CifasCaseCommon} from "../../common/cifas-case-common";
import {TuCifasDto} from "../dto/tu-cifas-dto";
import {Cifas} from "../definitions/Cifas";
import {CifasCase} from "../definitions/CifasCase";

export class TransunionTransformationService {

  convertCifasData(cifas: Cifas): Array<CifasCaseCommon> {
    const cifasResult: Array<TuCifasDto> = [];

    cifas?.CifasCases?.CifasCase?.forEach((cifasCase: CifasCase) => {
      cifasResult.push(new TuCifasDto(cifasCase));
    })

    return cifasResult;
  }

  // convertJudgements(report: TransunionRawReport) {
  //   report.judgementsCommon = report
  //     .getJudgmentsTransunion()
  //     .map(j => {
  //       const newJ: JudgementCommon = {
  //         Address: '',
  //         Amount: 0,
  //         CaseNumber: '',
  //         CasePerId: '',
  //         CourtName: '',
  //         CourtType: '',
  //         CurrencyCode: '',
  //         JudgmentDate: '',
  //         Name: '',
  //         Notices: undefined,
  //         SatisfiedDate: '',
  //         Status: ''
  //       };
  //
  //       return Object.assign(newJ, j);
  //   });
  // }

  /*convertInsolvencies(report: TransunionRawReport) {
    report.insolvenciesCommon = report
      .getInsolvenciesTransunion()
      .map(j => {
        const newJ: InsolvencyCommon = {};

        return Object.assign(newJ, j);
    });
  }*/

  /*convertFinancialAccounts(report: TransunionRawReport) {
    report.creditCardsCommon = report
      .getFinancialAccountInformationTransunion(AccountType.CreditCards)
      .map(acct => new TuFinancialAccountDto(acct, AccountType.CreditCards));

    report.mortgagesCommon = report
      .getFinancialAccountInformationTransunion(AccountType.Mortgages)
      .map(acct => new TuFinancialAccountDto(acct, AccountType.Mortgages));

    report.personalLoansCommon = report
      .getFinancialAccountInformationTransunion(AccountType.PersonalLoans)
      .map(acct => new TuFinancialAccountDto(acct, AccountType.PersonalLoans));

    report.otherAccountsCommon = report
      .getFinancialAccountInformationTransunion(AccountType.OtherAccounts)
      .map(acct => new TuFinancialAccountDto(acct, AccountType.OtherAccounts));

    report.closedAccountsCommon = report
      .getFinancialAccountInformationTransunion(AccountType.ClosedAccounts)
      .map(acct => new TuFinancialAccountDto(acct, AccountType.ClosedAccounts));
  }*/
}

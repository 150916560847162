import {Component, OnInit} from '@angular/core';
import {ToastService} from '../toast.service';
import {ToastType} from '../toast-type';
import {NgbToast, NgbToastHeader} from '@ng-bootstrap/ng-bootstrap';
import {NgForOf, NgIf, NgSwitch, NgSwitchCase} from '@angular/common';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  standalone: true,
  imports: [
    NgbToast,
    NgIf,
    NgbToastHeader,
    NgSwitch,
    NgSwitchCase,
    NgForOf,
  ],
})
export class ToastComponent implements OnInit {

  public toastType = ToastType;

  // ToastService is used in the view
  constructor(public toastService: ToastService) {}

  ngOnInit(): void {
  }

}

import {Equifax} from "../equifax";
import {AddressDto} from "../dto/address-dto";
import {AddressLinksCommon} from "../../common/address-links-common";
import {AddressLinkDetailDto} from "../dto/address-link-detail-dto";
import {AddressLinkDetailCommon} from "../../common/address-link-detail-common";
import {InsightAccountTypes} from "../models/insight-account-types";
import {NameMatch} from "../models/name-match";

/**
 * This contains functions to produce standardized versions of raw report elements.  This process has been moved server side, but is kept
 * around commented out just in case.
 */
export class EquifaxTransformationService {
  /*private readonly JUDGEMENT_TYPES = ['CJ', 'CD'];
  private readonly INSOLVENCY_TYPES = ['ADJ', 'BKY', 'BRO', 'BRU'];*/

  /*convertJudgementsAndInsolvencies(report: EquifaxRawReport): void {
    report.judgementsCommon = [];
    report.insolvenciesCommon = [];

    report.soleSearch?.primary?.suppliedAddressData?.forEach((suppliedAddressData: Equifax.SuppliedAddressData) => {
      suppliedAddressData?.addressSpecificData?.courtAndInsolvencyInformationData?.courtAndInsolvencyInformation
        .forEach((courtAndInsolvencyInformation: Equifax.CourtAndInsolvencyInformation) => {
          if (this.JUDGEMENT_TYPES.indexOf(courtAndInsolvencyInformation.ccjType)) {
            report.judgementsCommon.push(new JudgementDto(
              courtAndInsolvencyInformation,
              suppliedAddressData?.matchedAddress,
            ));
          } else if (this.INSOLVENCY_TYPES.indexOf(courtAndInsolvencyInformation.ccjType)) {
            report.insolvenciesCommon.push(new InsolvencyDto(
              courtAndInsolvencyInformation,
              suppliedAddressData?.matchedAddress,
            ));
          }
        });
    });
  }*/

  /**
   * Get Disputes & Notices of Correction
   *
   * Note: EQ Notices of Corrections and Disputes are lumped together.
   * @param soleSearch
   */
  /*extractNoticesOfCorrection(soleSearch: ResponseSoleSearch): Array<NoticeCommon> {
    const notices: Array<NoticeCommon> = [];

    soleSearch?.primary?.suppliedAddressData?.forEach((suppliedAddressData) => {
      suppliedAddressData?.addressSpecificData?.noticeOfCorrectionOrDisputeData?.noticeOfCorrectionOrDispute.forEach((noticeOfCorrectionOrDispute: Equifax.NoticeOfCorrectionOrDispute) => {
        notices.push(new NoticesOfCorrectionDto(noticeOfCorrectionOrDispute));
      })
    });

    soleSearch?.primary?.linkedAddressData?.forEach((suppliedAddressData) => {
      suppliedAddressData?.addressSpecificData?.noticeOfCorrectionOrDisputeData?.noticeOfCorrectionOrDispute.forEach((noticeOfCorrectionOrDispute: Equifax.NoticeOfCorrectionOrDispute) => {
        notices.push(new NoticesOfCorrectionDto(noticeOfCorrectionOrDispute));
      })
    });

    return notices;
  }*/


  /*simplifyElectoralRoll(soleSearch: ResponseSoleSearch): Array<ElectoralRollEquifax> {

    // Fetch
    const results = [];

    soleSearch?.primary?.suppliedAddressData?.forEach((element) => {
      results.push({
        electoralRollData: element.addressSpecificData.electoralRollData,
        matchedAddress: element.matchedAddress,
      });
    });

    soleSearch?.primary?.linkedAddressData?.forEach((element) => {
      results.push({
        electoralRollData: element.addressSpecificData.electoralRollData,
        matchedAddress: element.linkedAddress,
      });
    });

    soleSearch?.primary.suppliedAddressData.forEach((element) => {

    });

    // Simplify
    const simplifiedElectoralRoll: Array<ElectoralRollEquifax> = [];

    results.forEach((result) => {
      result.electoralRollData.electoralRoll.forEach((electoralRoll: Equifax.ElectoralRoll) => {
        simplifiedElectoralRoll.push({
          electoralRoll: electoralRoll,
          matchedAddress: result.matchedAddress,
        });
      })
    });

    // Sort
    simplifiedElectoralRoll.sort((a, b) => {
      // If end years are equal, then sort by start year
      if (a.electoralRoll.annualRegisterPeriod.end === b.electoralRoll.annualRegisterPeriod.end) {
        if (a.electoralRoll.annualRegisterPeriod.start < b.electoralRoll.annualRegisterPeriod.start) {
          return 1;
        } else if (a.electoralRoll.annualRegisterPeriod.start > b.electoralRoll.annualRegisterPeriod.start) {
          return -1
        }
        // otherwise, just sort on end year
      } else {
        if (a.electoralRoll.annualRegisterPeriod.end < b.electoralRoll.annualRegisterPeriod.end) {
          return 1;
        } else if (a.electoralRoll.annualRegisterPeriod.end > b.electoralRoll.annualRegisterPeriod.end) {
          return -1
        }
      }

      return 0;
    })

    return simplifiedElectoralRoll;
  }*/

  /*extractCifasData(soleSearch: Equifax.ResponseSoleSearch): Array<CifasCaseCommon> {

    const cifasResult: Array<CifasDto> = [];

    soleSearch?.primary?.suppliedAddressData?.forEach((suppliedAddressData) => {
      suppliedAddressData?.addressSpecificData?.cifasData?.cifas.forEach((cifas: Equifax.CIFAS) => {
        cifasResult.push(new CifasDto(cifas, suppliedAddressData.matchedAddress));
      })
    });

    return cifasResult;
  }*/


  getAddressLinks(soleSearch: Equifax.ResponseSoleSearch): AddressLinksCommon {
    return {
      addresses: this.getAllAddresses(soleSearch),
      details: this.getAddressLinkDetail(soleSearch),
    }
  }


  getAllAddresses(soleSearch: Equifax.ResponseSoleSearch): Array<any> {
    const addressResult = [];

    soleSearch?.primary?.suppliedAddressData?.forEach((suppliedAddressData) => {
      addressResult.push(new AddressDto(suppliedAddressData.matchedAddress.address).Address);
    });

    soleSearch?.primary?.linkedAddressData?.forEach((linkedAddressData) => {
      addressResult.push(new AddressDto(linkedAddressData.linkedAddress.address).Address);
    });

    return addressResult;
  }


  getAddressLinkDetail(soleSearch: Equifax.ResponseSoleSearch): Array<AddressLinkDetailCommon> {
    const addressResult = [];

    // Making assumption here that the first address is right if multiple addresses come back.  If multiple do come back,
    // this should be resolved prior to Front End as we are looking for single match
    const searchAddress = soleSearch?.primary?.suppliedAddressData[0];

    soleSearch?.primary?.linkedAddressData?.forEach((linkedAddressData) => {
      addressResult.push(new AddressLinkDetailDto(linkedAddressData, searchAddress.matchedAddress.address));
    });

    return addressResult;
  }

  /*processFinancialAccounts(report: EquifaxRawReport) {
    report.otherAccountsCommon = [];
    report.personalLoansCommon = [];
    report.closedAccountsCommon = [];
    report.mortgagesCommon = [];
    report.creditCardsCommon = [];

    return report
      .getDataSection('insightData')

      // Data coming from here is grouped by account type {creditCards: [{..},{..]], currentAccount: [{..}]}
      // This will reduce it to an array of FinancialAccountCommon
      .reduce<FinancialAccountCommon[]>((prev, cur) => {
        Object.keys(cur).forEach(accountType => {
          const commons = cur[accountType].map(account => new FinancialAccountDto(account, accountType))
          prev = prev.concat(commons);
        })

        return prev;
      }, [])

      .forEach(account => {
        if (account.AccountType == AccountType.ClosedAccounts) {
          report.closedAccountsCommon.push(account);
        } else if (account.AccountType == AccountType.CreditCards) {
          report.creditCardsCommon.push(account);
        } else if (account.AccountType == AccountType.PersonalLoans) {
          report.personalLoansCommon.push(account);
        } else if (account.AccountType == AccountType.Mortgages) {
          report.mortgagesCommon.push(account);
        } else if (account.AccountType == AccountType.OtherAccounts) {
          report.otherAccountsCommon.push(account);
        }
      });
  }*/


  getAllNamesFromAccountData(soleSearch: Equifax.ResponseSoleSearch) {
    let data: Array<NameMatch> = [];
    //const names: Array<Equifax.Name> = [];

    soleSearch?.primary?.suppliedAddressData?.forEach((suppliedAddressData) => {
      data = this.getInsightDataAccountSection(suppliedAddressData, ['name', 'nameMatchStatus', 'dob']);
    });

    soleSearch?.primary?.linkedAddressData?.forEach((linkedAddressData) => {
      data = [ ...data, ...this.getInsightDataAccountSection(linkedAddressData, ['name', 'nameMatchStatus', 'dob'])];
    });


    // data.forEach((n) => {
    //   if (n.nameMatchStatus === 'A') { // Note: 'A' is the subject themselves
    //     names.push(n.name);
    //   }
    // });

    return data.filter((n: NameMatch) => n.nameMatchStatus === 'A');
  }



  getInsightDataAccountSection(
    addressData: Equifax.SuppliedAddressData | Equifax.LinkedAddressData,
    propertiesToExtract: Array<string>
  ): Array<any> {

    const data = [];

    if (addressData.addressSpecificData.insightData) {
      const insightProperties = Object.getOwnPropertyNames(addressData?.addressSpecificData?.insightData);

      // Get array of all known account types to filter against from the InsightAccountTypes Enum   e.g. ['bankDefaultAgreement','budgetAccount','chargeCard','commsSupplyAccount', ...]
      const insightAccountTypes = Object.getOwnPropertyNames(InsightAccountTypes)
        .filter((property) => isNaN(parseInt(property))); //filters the enum values 0, 1, 2 that also come back from with the names

      // Iterate over all insight properties, skipping over non account properties
      insightProperties.forEach((iProperty: string) => {
        // Make sure we are really dealing with an account type as there may be other non account properties we are iterating over
        if (insightAccountTypes.includes(iProperty)) {
          addressData?.addressSpecificData?.insightData[iProperty]?.forEach((account: Equifax.CurrentAccount) => {

            const myObj = {};

            propertiesToExtract.forEach((propName: string) => {
              if (Object.prototype.hasOwnProperty.call(account, propName))
                myObj[propName] = account[propName];
            });
            data.push(myObj);
          });
        }
      });
    }

    return data;
  }

}

import {Component} from '@angular/core';
import {BrandService} from '../../../services/brand.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {

  public brandDomain = '';
  public csEmail = '';
  public currentYear: number;

  constructor() {
    this.currentYear = (new Date()).getFullYear();
    this.brandDomain = BrandService.getDomain();
    this.csEmail = BrandService.getCsEmail();
  }
}

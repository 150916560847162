import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {AuthService} from '../services/auth.service';

/**
 * If any ajax call results in a 401, destroy our token and go to login.  This mainly kicks in when user's browser
 * sits around for a while and their session times out, then they try and browse around.
 */
@Injectable()
export class LogoutOnUnauthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next
      .handle(req)
      .pipe(
        tap({
          next: () => {
          },
          error: err => {
            if (err instanceof HttpErrorResponse) {
              if (
                // If we are already on the login page, don't bother logging us out.
                err.url.indexOf('api/auth/one-time-password') > 0
                || err.url.indexOf('api/auth/login') > 0

                // If we are doing some auth operation already, we don't need to redirect to login
                || req.url.indexOf('api/auth') > 0
              ) {
                return;
              }

              if (err.url.indexOf('api/auth/logout') === -1 && err.status === 401) {
                this.authService.logout(true);
              }
            }
          },
        }),
      );
  }
}

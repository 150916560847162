<div class="modal-header text-end">
<!--  <button type="button" class="close clickable" data-dismiss="modal" aria-label="Close" (click)="close()">-->
<!--    <span aria-hidden="true">&times;</span>-->
<!--  </button>-->
  &nbsp;
  <i class="fa-lg fa-regular fa-circle-xmark close clickable" data-dismiss="modal" aria-label="Close" (click)="close()"></i>
</div>
<div class="modal-body text-center px-5">

  <div class="icon-container">
    <img class="my-2 img-fluid justify-content-center align-self-center h-100" src='/assets/images/cta/ICN_Handshake.svg' />
  </div>

  <h5 class="py-3">You have been Matched!</h5>

  <div class="offer-list pb-3">
    <div class="card mt-3" *ngFor="let cta of ctaItem$ | async">
      <div class="card-body clickable px-0 py-2">

        <div class="icon-container mx-auto">
          <img class="my-2 img-fluid justify-content-center align-self-center" src='/assets/images/cta/{{cta.icon}}'/>
        </div>

        <h5 class="pt-3">{{cta.title}}</h5>

        <div class="desc m-0">
          <p class="m-0 p-3" [innerHTML]="cta.desc"></p>
        </div>

        <div class="d-grid gap-2 col-6 mx-auto">
          <button
            type="button"
            class="btn btn-lg rounded-pill btn-primary"
            (click)="doAction(cta)">
            {{cta.btn_label}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class AuthSanctumInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      // Whether or not cross-site Access-Control requests should be made using credentials such as cookies
      // withCredentials: true,

      // Make our requests ask for JSON.  This affects how Fortify will reply to our auth commands.
      headers: req.headers.set('Accept', 'application/json'),
    });

    return next.handle(req);
  }
}

import {Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {CreditReportService} from 'src/app/shared/services/credit-report.service';
import {RatingCommon} from 'src/app/shared/models/report/common/rating-common';
import {UserStatus} from 'src/app/shared/models/user-status';
import {BrokenEnrollService} from '../../../iao-chain/broken-enroll/broken-enroll.service';
import {Observable, Subscription} from 'rxjs';
import {AccountService} from 'src/app/shared/services/account.service';
import {IAccount} from 'src/app/shared/models/account';
import {map} from 'rxjs/operators';
import {CreditReport} from 'src/app/shared/models/report/credit-report';


@Component({
  selector: 'app-credit-score',
  templateUrl: './credit-score.component.html',
  styleUrls: ['./credit-score.component.scss']
})
export class CreditScoreComponent implements OnInit, OnDestroy {

  @ViewChild('dashPlaceholder', {static: true}) dashPlaceholder: TemplateRef<any>;
  @ViewChild('scoredPlaceholder', {static: true}) scoredPlaceholder: TemplateRef<any>;
  @ViewChild('noReportManualVerify', {static: true}) noReportManualVerify: TemplateRef<any>;
  @ViewChild('noReportThinFile', {static: true}) noReportThinFile: TemplateRef<any>;
  @ViewChild('noReportActive', {static: true}) noReportActive: TemplateRef<any>;
  @ViewChild('noReportBrokenEnrollment', {static: true}) noReportBrokenEnrollment: TemplateRef<any>;
  @ViewChild('noReportActiveNewCustomer', {static: true}) noReportActiveNewCustomer: TemplateRef<any>;

  rating$: Observable<RatingCommon>;

  public scoreViewTemplate: TemplateRef<any>;

  public today = '';

  @Input() public userStatus: string;
  @Input() public disableWarningAction = false;

  private reportSub: Subscription;
  account$: Observable<IAccount>;


  constructor(
    private accountService: AccountService,
    public reportService: CreditReportService,
    private brokenEnrollService: BrokenEnrollService,
  ) {
    this.setTodayDate();
    this.account$ = this.accountService.getAccount();

    this.rating$ = reportService.get().pipe(map(rpt => rpt.getRating()));
  }

  ngOnInit(): void {
    // Placeholder needs to be set in ngOnInit(), otherwise we get an ExpressionChangedAfterItHasBeenCheckedError
    // saying that scoreViewTemplate used to be undefined, then changed template.
    if (this.userStatus === UserStatus.BrokenEnrollment) {
      this.scoreViewTemplate = this.noReportBrokenEnrollment;
    } else if (this.userStatus === UserStatus.Manual) {
      this.scoreViewTemplate = this.noReportManualVerify;
    } else if (this.userStatus === UserStatus.ThinFile) {
      this.scoreViewTemplate = this.noReportThinFile;
    } else {
      this.scoreViewTemplate = this.dashPlaceholder;
    }

    this.reportSub = this.reportService
      .get()
      .subscribe((creditReport: CreditReport) => {
        if (!creditReport.isEmpty()) {
          this.scoreViewTemplate = this.scoredPlaceholder;
         } else {
          this.setNoReportTemplate(creditReport);
        }
      });
  }

  private setNoReportTemplate(creditReport: CreditReport): void {

    if (creditReport.reportUnavailable) {
      if (this.userStatus === UserStatus.Active) {
        this.scoreViewTemplate = this.noReportActive;
      } else if (this.userStatus === UserStatus.Cancelled) {
        // Assuming a "no match" result from broken enrollment KBA
        this.scoreViewTemplate = this.noReportActiveNewCustomer;
      }

    } else if (this.userStatus === UserStatus.BrokenEnrollment) {
      this.scoreViewTemplate = this.noReportBrokenEnrollment;

    } else if (this.userStatus === UserStatus.Manual) {
      this.scoreViewTemplate = this.noReportManualVerify;

    } else if (this.userStatus === UserStatus.ThinFile) {
      this.scoreViewTemplate = this.noReportThinFile;
    }
  }

  private setTodayDate(){
    const date = new Date();
    this.today = date.getDate() + '/' + ((date.getMonth() + 1)) + '/' + date.getFullYear();
  }

  completeRegistration() {
    this.brokenEnrollService.start();
  }

  ngOnDestroy(): void {
    this.reportSub.unsubscribe();
  }
}

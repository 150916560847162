import {Component} from '@angular/core';
import {AuthService} from 'src/app/core/services/auth.service';
import {Router} from '@angular/router';
import {LoadIndicatorService} from 'src/app/core/components/loadindicator/loadindicator.service';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {genericErrorObserver} from '../../../observables/generic-error.observer';
import {FileSaverService} from 'ngx-filesaver';
import {MixpanelService} from '../../../mixpanel/mixpanel.service';
import {AccountService} from '../../../services/account.service';
import {TrustpilotService} from 'src/app/modules/trustpilot/trustpilot.service';
import {HowToService} from 'src/app/modules/iao-chain/intro/how-to/how-to.service';
import {BrandService} from '../../../services/brand.service';
import {CreditConsultationIaoModalService} from 'src/app/modules/iao-chain/credit-consultation/credit-consultation-iao-modal.service';
import {CreditReportService} from 'src/app/shared/services/credit-report.service';
import {Agency} from 'src/app/shared/models/agency';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent {

  constructor(private authService: AuthService,
              private howToService: HowToService,
              private router: Router,
              private loadIndicatorService: LoadIndicatorService,
              private http: HttpClient,
              private fileSaverService: FileSaverService,
              public accountService: AccountService,
              public trustpilotService: TrustpilotService,
              private consultModalService: CreditConsultationIaoModalService,
              private creditReportService: CreditReportService,
  ) { }

  beginIntro() {
    this.loadIndicatorService.push('intro-wait');

    this.router
      .navigate(['/dashboard/home'])
      .then(() => {
        setTimeout(() => {
          this.loadIndicatorService.pop('intro-wait');
          this.howToService.run();
        }, 500);
      });
  }

  downloadReport(): void {
    // If this service is supported by the browser, we can use our progress bar page.
    if (this.fileSaverService.isFileSaverSupported) {
      this.router.navigateByUrl('/account/download-report');
    } else {
      // Otherwise, just work like it always has.
      MixpanelService.track('Click Direct download report');
      const selectedAgency = this.creditReportService.getSelectedAgencySnapshot();

      // We should put a proper modal which is aware of their current status (MV, broken enroll, thin file) and links accordingly.
      if (selectedAgency === Agency.NONE) {
        alert("You don't appear to have a credit report.  Finish your registration process to continue.");
        return;
      }

      window.open(`/api/report/pdf?agency=${selectedAgency}`, '_blank');
    }
  }

  viewReport(): void {
    MixpanelService.track('Click View printable report nav item');

    const selectedAgency = this.creditReportService.getSelectedAgencySnapshot();

    // We should put a proper modal which is aware of their current status (MV, broken enroll, thin file) and links accordingly.
    if (selectedAgency === Agency.NONE) {
      alert("You don't appear to have a credit report.  Finish your registration process to continue.");
      return;
    }

    window.open(`/api/report/print?agency=${selectedAgency}`, '_blank');
  }

  /**
   * Logout (and go to specific logout page)
   */
  logout() {
    MixpanelService.track('Click logout menu item');

    this.authService.logout(false, true);
  }

  isProduction(): boolean {
    return environment.production;
  }

  /**
   * Cause a 404 error.  This is strictly so we can test any interceptors, like sentry.io modals.
   */
  fakeClientError(): void {
    this.http
      .get('/missing-page')
      .subscribe(genericErrorObserver);

    throw new Error('this is a fake error');

    // Fake chunk loading error
    // throw new Error('Failed to fetch dynamically imported module: https://member.ukcreditratings.com/chunk-BD5VV6AG.js');
  }

  signPostingLink(){
    return BrandService.getSignPostingLink();
  }

  showConsultationModal(): void {
    this.consultModalService.run().then(
      () => console.log('Credit consult modal done'),
      () => console.log('Credit consult modal abandoned'),
    );
  }
}

import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OfferCategoryService {

  public currentWindowWidth = 480;
  public iconsToDisplay = 4;
  public numberOfSlides = 1;
  public currentSlide = 1;
  public start = 0;
  public end = 4;
  public minCatItemWidth = 120;  // should match CSS .cat-item width

  public initValues(width: number, numberOfCategories: number) {

    this.currentWindowWidth = width;
    this.iconsToDisplay = Math.floor(this.currentWindowWidth / this.minCatItemWidth);
    this.numberOfSlides = Math.ceil(numberOfCategories / this.iconsToDisplay);

    this.start = 0;
    if (this.iconsToDisplay < numberOfCategories) {
      this.end = this.iconsToDisplay;
    } else {
      this.end = numberOfCategories;
    }
  }

  /**
   * View previous slide or circle around to last slide if already on first slide.
   */
  navLeft(): void {
    this.currentSlide = (this.currentSlide > 1) ? this.currentSlide - 1 : this.numberOfSlides;
    this.setSliderRange();
  }

  /**
   * View next slide or circle around to first slide if already on last slide.
   */
  navRight(): void {
    this.currentSlide = (this.currentSlide < this.numberOfSlides) ? this.currentSlide + 1 : 1;
    this.setSliderRange();
  }

  /**
   * Set display range of sliders ngFor loop
   */
  setSliderRange(): void {
    this.start = (this.currentSlide - 1) * this.iconsToDisplay;
    this.end = this.currentSlide * this.iconsToDisplay;
  }

  /**
   * Hide Nav Arrows if there is only one slide
   */
  canDisplayNav(): boolean {
    return this.numberOfSlides > 1;
  }
}

import {Injectable, Injector, OnDestroy} from '@angular/core';
import {IaoEventService} from './iao-event.service';
import {IaoChainProvider} from './models/iao-chain-provider';
import {IaoChainProviders} from './iao-chain-providers';
import {ActivatedRoute} from '@angular/router';
import {Chainable} from './models/chainable';
import {IaoModalResultEnum} from './iao-modal-result.enum';
import {IaoEventTypeEnum} from './iao-event-type.enum';
import {MixpanelService} from '../../shared/mixpanel/mixpanel.service';
import {Observable} from 'rxjs';

/**
 * IaoChainService
 *
 * Runs through all available IAOs and displays if criteria is met.
 */
@Injectable({
  providedIn: 'root'
})
export class IaoChainService implements OnDestroy {

  readonly DEFAULT_DELAY = 500;
  readonly FIRST_DELAY = 2500;
  private isTestChain = false;

  constructor(
    private injector: Injector,
    private iaoEventService: IaoEventService,
    private iaoChain: IaoChainProviders,
    private route: ActivatedRoute
  ) {
    this.iaoEventService.iaoCloseEvent.subscribe(event => {
      if (event !== IaoEventTypeEnum.Stop && this.iaoChain.hasNext()) {
        this.runProvider(this.iaoChain.next()).then();
      }
    });
  }

  ngOnDestroy(): void {
     this.iaoEventService.iaoCloseEvent.unsubscribe();
  }

  /**
   * Display Intros, Offers, Announcements (IAO) one at a time
   *
   * Subscribes to modal close events and then fires off the first modal
   */
  public start(): void {
    // TODO this is where resume would probably happen.  Instead of beginning only if we haven't started
    // we could begin if it hasn't finished.
    if (!this.iaoChain.hasStarted()) {
      this.checkIfTest();

      // Start the first
      setTimeout(() => this.iaoEventService.start(), this.FIRST_DELAY);
    }
  }

  /**
   * Reset IAO Chain back to beginning and start it
   * Use of this is rare as it will run the whole chain.
   */
  public restart() {
    this.iaoChain.reset();
    this.iaoEventService.start();
  }

  /**
   * Checks for presence of isTestChain route parameter & gets its value.  If TRUE, will display all IAOs
   */
  private checkIfTest(): void {
    this.route.queryParams.subscribe(params => {
      this.isTestChain = params['isTestChain'];
    });
  }

  /**
   * Run the specific IAO Provider if conditions are met
   * @param modalChainProvider A service provider that implements Chainable
   */
  private async runProvider(modalChainProvider: IaoChainProvider) {

    // @ts-expect-error Can't figure out this interface related issue
    const service = this.injector.get<Chainable>(modalChainProvider.service);
    const isTestProvider = this.route.snapshot.queryParams[modalChainProvider.testFlag];
    const delay = ('delay' in modalChainProvider) ? modalChainProvider.delay : this.DEFAULT_DELAY;

    // Resolve dependencies then run
    await service.resolveDependencies();

    if (service.canDisplay() || this.isTestChain || isTestProvider) {
      await this.delay(delay);

      MixpanelService.track('ShowModal IAO ' + modalChainProvider.iaoName);

      let result;
      try {
        result = await service.run();
      } catch (e) {
        // This is when the service.run() promise is rejected.  For a boostrap modal, that can be when the modal is dismissed.  Let's keep
        // going unless we're explicitly told to stop.
        // https://ng-bootstrap.github.io/#/components/modal/api#NgbModalRef

        result = IaoModalResultEnum.ContinueChain;
      }

      service.onIaoClose();

      if (result === IaoModalResultEnum.StopChain) {
        this.iaoEventService.stop();
      } else {
        this.iaoEventService.showNext();
      }
    } else if (!this.iaoChain.hasNext()) {
      this.iaoEventService.stop();
    } else {
      // Skipped modal, so notify next can run
      this.iaoEventService.showNext();
    }
  }

  private async delay(duration) {
    return new Promise(resolve => setTimeout(resolve, duration));
  }

  numberOfModalsToDisplay(): Observable<number> {
    return new Observable<number>((subscriber) => {
      // Get an array of Chainable services, per IaoChainProviders
      const services = this.iaoChain.items.map(
        // @ts-expect-error Can't figure out this interface related issue
        (provider: IaoChainProvider) => this.injector.get<Chainable>(provider.service)
      );

      // Get an array of promises for the service dependencies
      const dependentsAvailable = services.map((service: Chainable) => service.resolveDependencies());

      // Wait for all dependencies to load
      Promise.all(dependentsAvailable).then(() => {
        let modalsToDisplay = 0;

        // Count up how many are going to be displayed.
        services.forEach((service) => {
          if (service.canDisplay()) {
            modalsToDisplay++;
          }
        });

        subscriber.next(modalsToDisplay);
        subscriber.complete();
      }, () => {
        subscriber.error(-1);
      });
    });
  }
}

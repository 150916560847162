/**
 * Base class for raw reports.  Is the report as it comes from the agency, and WON'T have standardized, common objects.
 * For standardized data:
 * @see CreditReport
 */
export abstract class AgencyRawReport {

  /**
   * Called after pulling the report from the server, this should run all DTOs and store the results in common member variables within this
   * class.
   */
  abstract transform(): void;

}

import {Component} from '@angular/core';
import {Observable} from 'rxjs';
import {AccountService} from 'src/app/shared/services/account.service';
import {map} from 'rxjs/operators';
import {DateTime} from 'luxon';

@Component({
  selector: 'app-trial-countdown',
  templateUrl: './trial-countdown.component.html',
  styleUrls: ['./trial-countdown.component.scss'],
})
export class TrialCountdownComponent {
  displayed$: Observable<boolean>;
  days$: Observable<number>;

  constructor(
    accountService: AccountService,
  ) {
    this.displayed$ = accountService
      .getAccount()
      .pipe(
        map(acct => acct.user.deferred_auth === 1 && acct.user.deferred_auth_complete === 0),
      );

    this.days$ = accountService
      .getAccount()
      .pipe(
        map(acct => {
          const trialEnd = DateTime.fromISO(acct.user.trial_end_date);
          return trialEnd.diffNow().days;
        }),
      );
  }
}

import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BrandService {

  private brand: string = null;

  /**
   * Gets "ukcreditratings.com" or "creditscorepro.co.uk"
   * Assumes domain is production, like "member.myukcreditrating.co.uk"
   */
  static getDomain(): string {
    return location.hostname.replace('member.', '');
  }

  static getTermsOfUseUrl(): string {
    return 'https://' + BrandService.getDomain() + '/terms.html';
  }

  static getPrivacyPolicyUrl(): string {
    return 'https://' + BrandService.getDomain() + '/privacy.html';
  }

  static getRefundPolicyUrl(): string {
    return 'https://' + BrandService.getDomain() + '/refundpolicy.html';
  }

  static getCsEmail(): string {
    return 'info@' + BrandService.getDomain();
  }

  static getSignPostingLink(): string {
    return 'https://' + BrandService.getDomain() + '/signposting';
  }

  constructor() {
  }

  private static extractFromUrl(): string {
    const parts = location.hostname.split('.');

    while (parts.length > 0) {
      const fragment = parts.pop();

      if (!BrandService.inArray(fragment)) {
        return fragment.toLowerCase();
      }
    }

    return '';
  }

  private static inArray(str): boolean {
    const keywords = ['com', 'co', 'uk', 'net'];
    return keywords.indexOf(str) > -1;
  }

  /**
   * Returns something like "ukcreditratings"
   */
  public get(): string {
    if (this.brand == null) {
      this.brand = BrandService.extractFromUrl();
    }

    return this.brand;
  }

  /**
   * Returns the backend url based on the current domain and environment
   */
  public getBackendUrl(): string {

    if (environment.production) {
      const brand = this.get();
      return 'https://api.member.' + brand + '.com';  // TODO: Change to production url

    } else if (environment.staging) {
      const brand = this.get();
      return 'https://api.stage.member.' + brand + '.com';

    } else {
      return environment.backendUrl;
    }
  }
}

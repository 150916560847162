<ng-template #dashPlaceholder>
  <span class="text-muted section-value">-</span>
</ng-template>

<div id='summary-sec' class="summary-section-nav" #summaryNav>
  <div #summaryScore id="summary-nav-credit-score" class="summary-section-nav-item flex-fill" routerLinkActive="active">
    <a [routerLink]="['/dashboard/home', { outlets: { 'summary': ['summary-credit-score'] } }]">
      <span class="section-value" [countUp]="(summaryStats$ | async).score" [reanimateOnClick]="false" *ngIf="!!(summaryStats$ | async); else dashPlaceholder">0</span>
      <div class="section-label">Your Credit Score</div>
    </a>
  </div>

  <div #summaryPayment id="summary-nav-payment-history" class="summary-section-nav-item flex-fill" routerLinkActive="active">
    <a [routerLink]="['/dashboard/home', { outlets: { 'summary': ['summary-payment-history'] } }]">
      <span class="section-value" [countUp]="(summaryStats$ | async).paymentHistory" [reanimateOnClick]="false" *ngIf="!!(summaryStats$ | async); else dashPlaceholder">0</span>
      <span class="section-value" *ngIf="(summaryStats$ | async)?.paymentHistory;">%</span>
      <div class="section-label">Payment History</div>
    </a>
  </div>

  <div #summaryNegative id="summary-nav-negative-marks" class="summary-section-nav-item flex-fill" routerLinkActive="active">
    <a [routerLink]="[{ outlets: { 'summary': ['summary-negative-marks'] } }]">
      <span class="section-value" [countUp]="(summaryStats$ | async).negativeMarks" [reanimateOnClick]="false" *ngIf="!!(summaryStats$ | async); else dashPlaceholder">0</span>
      <div class="section-label">Negative Impacts</div>
    </a>
  </div>

  <div #summaryInquiries id="summary-nav-hard-inquiries" class="summary-section-nav-item flex-fill" routerLinkActive="active">
    <a [routerLink]="[{ outlets: { 'summary': ['summary-hard-inquiries'] } }]">
      <span class="section-value" [countUp]="(summaryStats$ | async).hardInquiries" [reanimateOnClick]="false" *ngIf="!!(summaryStats$ | async); else dashPlaceholder">0</span>
      <div class="section-label">Hard Searches</div>
    </a>
  </div>

  <div #summaryCard id="summary-nav-credit-utilization" class="summary-section-nav-item flex-fill" routerLinkActive="active">
    <a [routerLink]="[{ outlets: { 'summary': ['summary-credit-card-utilization'] } }]">
      <span class="section-value" [countUp]="(summaryStats$ | async).creditCardUtilization" [reanimateOnClick]="false" *ngIf="!!(summaryStats$ | async); else dashPlaceholder">0</span>
      <span class="section-value" *ngIf="(summaryStats$ | async)?.creditCardUtilization;">%</span>
      <div class="section-label">Credit Card Utilisation</div>
    </a>
  </div>

  @if (isTransunion$ | async) {
  <div #summaryDisputes id="summary-nav-disputes" class="summary-section-nav-item flex-fill" routerLinkActive="active">
    <a [routerLink]="[{ outlets: { 'summary': ['summary-disputes'] } }]">
      <span class="section-value" [countUp]="(openDisputes$ | async)?.length" [reanimateOnClick]="false" *ngIf="!!(summaryStats$ | async); else dashPlaceholder">0</span>
      <div class="section-label">Open Disputes</div>
    </a>
  </div>
  }

  <div class="clearfix"></div>
</div>

import {Component, ElementRef, ViewChild} from '@angular/core';
import {MixpanelService} from 'src/app/shared/mixpanel/mixpanel.service';
import {SmartOfferService} from 'src/app/shared/services/smart-offer-service';
import {CtaCategory} from 'src/app/shared/models/cta/cta-category.enum';
import {CtaCategoryService} from 'src/app/shared/services/cta-category.service';
import {CtaCategoryInterface} from 'src/app/shared/models/cta/cta-category.interface';
import {OfferCategoryService} from "./offer-category-service";
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {filter, map} from 'rxjs/operators';
import {NgForOf, NgIf, NgOptimizedImage, SlicePipe} from '@angular/common';


@Component({
  selector: 'app-offer-category-ctas',
  templateUrl: './offer-category-ctas.component.html',
  styleUrls: ['./offer-category-ctas.component.scss'],
  standalone: true,
  imports: [
    NgForOf,
    SlicePipe,
    NgOptimizedImage,
    NgIf,
  ],
  host: {
    '(window:resize)': 'onResize()',
  },
})
export class OfferCategoryCtasComponent {

  public categories: Array<CtaCategoryInterface> = [];

  // Slider properties
  @ViewChild('window') container: ElementRef<HTMLDivElement>;

  constructor(
    public smartOfferService: SmartOfferService,
    private ctaCategoryService: CtaCategoryService,
    public offerCategoryService: OfferCategoryService,
  ) {
    // Offer Categories are a count of the subset of CTA's that are offers.  So they rely on CTA processing to be complete
    this.ctaCategoryService
      .get()
      .pipe(
        takeUntilDestroyed(),

        // Don't bother if we don't have data yet
        filter(v => v != null),

        // Remove categories that have no offers
        map(cats => cats.filter(cat => cat.count > 0)),
      )
      .subscribe({
        next: cats => {
          this.categories = cats;
          this.resetIconDisplay();
        },
      });
  }

  /**
   * Watch for browser resizing and reset Icon Display Width
   */
  onResize(): void {
    this.resetIconDisplay();
  }

  /**
   * Figures out slide container width and how many icons can fit per slide
   */
  private resetIconDisplay(): void {
    if (this.hasCategories() && this.container?.nativeElement) {
      this.offerCategoryService.initValues(this.container.nativeElement.offsetWidth, this.categories.length);
    }
  }

  hasCategories(): boolean {
    return (this.categories?.length > 1);
  }

  /**
   * Open smart offer modal filtered to the selected category
   */
  async openSmartOffers(ctaCategory: CtaCategory) {
    MixpanelService.track('SecondaryNav - Click smart offers');

    await this.smartOfferService.open(ctaCategory);
  }
}

<p class=" text-center font-color-alt" *ngIf="hasCategories()">Personalised Offers:</p>

<div class="cat-slider">
  <div class="cat-nav cat-nav-left" (click)="offerCategoryService.navLeft()" tabindex="0">
    <i class="fa-solid fa-lg font-ukc-blue fa-chevron-left" *ngIf="offerCategoryService.canDisplayNav()"></i>
  </div>

  <div #window class="window">

    <div
      tabindex="0"
      class="cat-item clickable"
      (click)="openSmartOffers(category.id)"
      *ngFor="let category of categories | slice:offerCategoryService.start:offerCategoryService.end">

      <div class="icon-container">
        <img [alt]="category.name" [ngSrc]="category.icon" height="50" width="50" />
      </div>
      <div class="badge-container" *ngIf="smartOfferService.hasConsentedSmartOffers()">
        <span class="badge rounded-pill text-bg-primary text-white px-2">{{category.count}}</span>
      </div>
      <div class="label-container">
        <div>{{category.name}}</div>
      </div>
    </div>

  </div>

  <div class="cat-nav cat-nav-right" (click)="offerCategoryService.navRight()" tabindex="0">
    <i class="fa-solid fa-lg font-ukc-blue fa-chevron-right" *ngIf="offerCategoryService.canDisplayNav()"></i>
  </div>
</div>

import {Injectable} from '@angular/core';
import {Chainable} from '../models/chainable';
import {IAccount} from 'src/app/shared/models/account';
import {AccountService} from 'src/app/shared/services/account.service';
import {GoCardlessComponent} from './go-cardless.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {take} from 'rxjs/operators';
import {DataPartnerService} from 'src/app/shared/services/data-partner.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BillingScheme} from 'src/app/shared/models/billing-scheme.interface';

@Injectable({
  providedIn: 'root'
})
export class GoCardlessService implements Chainable {

  private account: IAccount;

  constructor(
    private accountService: AccountService,
    private modalService: NgbModal,
    private http: HttpClient,
  ) {}

  resolveDependencies(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.accountService
        .getAccount()
        .pipe(take(1))
        .subscribe((account: IAccount) => {
          this.account = account;
          resolve();
        });
    });
  }

  canDisplay() {
    return this.account.settings.hasSeenOfferGoCardless !== true
      // && this.account.user.proc_id !== 19) // UKC-2617
      && !DataPartnerService.isPartner(this.account.user.afid); // UKC-2475
  }

  run() {
    const modalRef = this.modalService.open(GoCardlessComponent, {size: 'md'});

    return modalRef.result;
  }

  onIaoClose() {
    this.accountService
      .updateSetting('hasSeenOfferGoCardless', true)
      .subscribe(() => {});
  }

  getDiscountedBillingScheme(): Observable<BillingScheme> {
    return this.http.get<BillingScheme>('/api/account/billing-schemes/direct-debit');
  }
}
